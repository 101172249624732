import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GlobalState } from '../global.state';
import { GlobalDataService } from '../globalData';
import { Auth } from '../auth.service';
import { environment } from '../../environments/environment';
import { AccountManager } from '../utils/accountManager';
import { Util } from '../utils/util.service';
import { filter, pairwise, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileUtil } from '../utils/mobileUtil.service';
import { RevisionService } from './modals/revisionManager/revision.service';
import { RevisionManagerUtil } from './modals/revisionManager/revisionManager.util';
import { CanDeactivateState } from './accounts/can-deactivate.guard';
import { SubscriptionDetailsUtil } from '../utils/subscriptionDetails.util';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Notification } from '../utils/dataInterfaces';
import { NotificationsComponent } from '../theme/components/baSidebar/components/baMenu/components/userMenu/components/notifications';
import { SettingsComponent } from './modals/settings';
import { OverridesDialogUtil } from '../utils/overiddesDialog.util';
import { AlertModalService } from '../reusableWidgets/alertModal';
import { UsersUtil } from '../utils/users.util';
import { EVENT_NAMES, PAGE_NAMES } from '../utils/enums';
import { AppStoreService } from '../store';
import { WealthFluentMessageService } from '../theme/services';
import { Logger } from '../utils/logger.service';
import { WorkspaceLoadedStore } from '../store/workspace';


@Component( {
  selector: 'nga-pages',
  styleUrls: [ './pages.scss' ],
  templateUrl: './pages.component.html',
} )
export class PagesComponent implements OnInit, AfterViewInit, OnDestroy {


  private readonly onDestroy: Subject<void> = new Subject<void>();

  editing: boolean;
  infoType: string;

  deviceIsMobile: boolean = false;

  showAlert: boolean = true;

  warningNotification: Notification;

  firstRetrievalDone: boolean = false;

  appName: string = environment.appName;

  constructor( private _auth: Auth,
               private _state: GlobalState,
               private _gdService: GlobalDataService,
               public router: Router,
               private _accountManager: AccountManager,
               private _detectorService: DeviceDetectorService,
               private _revisionService: RevisionService,
               private revManagerUtil: RevisionManagerUtil,
               // don't need these states to use this here yet, but need it to be initialized before a
               // dialog that uses them is opened
               public subDetailsUtil: SubscriptionDetailsUtil,
               public dialog: MatDialog,
               public bottomSheet: MatBottomSheet,
               // location: PlatformLocation,
               private _overridesDialogUtil: OverridesDialogUtil,
               private _alertService: AlertModalService,
               // this needs to be injected here to get it instantiated before it is tried to be used elsewhere
               private _subscriptionDetailsUtil: SubscriptionDetailsUtil,
               private appStoreService: AppStoreService,
               private _wealthFluentMessageService: WealthFluentMessageService,
  ) {

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_pagesComponent' ] = this;
    }

    /*    location.onPopState( () => {
     window.location.reload();
     } );*/

    // if the user clicks the back button, ask the CanDeactivateGuard to defend against this.
    window.onpopstate = () => {
      CanDeactivateState.defendAgainstBrowserBackButton = true;
    };

    // Upon successful navigation, ensure that the CanDeactivateGuard no longer defends against back button clicks
    router.events.pipe(
      filter( e => e instanceof NavigationEnd ),
      tap( () => {
        CanDeactivateState.defendAgainstBrowserBackButton = false;
      } ),
    ).subscribe();

    this.deviceIsMobile = MobileUtil.deviceIsMobile( this._detectorService );

    // if ( environment.env !== 'dev' ) {
    //   this.checkVersion();
    // }
  }

  ngOnInit() {

    if ( document.referrer !== '' && !this._auth.authenticated() ) {
      // inside wealthFluent
      this._wealthFluentMessageService.authenticatedViaWF.pipe( takeUntil( this.onDestroy ) ).subscribe( {
        next: ( authenticated: boolean ) => {
          if ( authenticated ) {
            this.setup();
          } else {
            //wtf just happened
          }
        },
      } );
    } else {
      this.setup();
    }

  }

  setup() {
    this.appStoreService.loadedWorkspace$
      .pipe(
        filter( Boolean ),
        startWith( null ),
        pairwise(),
        switchMap( ( [ prev, cur ] ) => {

          // stub
          if ( !prev ) {
            return of( null );
          }

          if ( prev.type === 'my' && cur.type === 'my' ) {
            return of( null );
          }

          if ( cur ) {
            return of( cur );
          }

        } ),
        takeUntil( this.onDestroy ),
      )
      .subscribe( ( workspace: WorkspaceLoadedStore ) => {
        Logger.log( `workspace loaded: ${ workspace }` );
        this._accountManager.turnOffAllRefreshes();
        this.setupAndRetrieveData();
        this.getDialogNeededData();
      } );

  }

  subscriberName = 'pagesComponent';

  ngAfterViewInit(): void {
    this._state.subscribe( EVENT_NAMES.EDITING_CHANGED, ( editingState ) => {
      this.editing = editingState;
    }, this.subscriberName );

    this._state.subscribe( EVENT_NAMES.SUBSCRIPTION_CHANGED, () => {
      this.setupAndRetrieveData();
    }, this.subscriberName );

    this._state.subscribe( EVENT_NAMES.WARNING_NOTIFICATION, ( warningNotification: Notification ) => {
      if ( warningNotification ) {
        this.setupWarningNotificationBanner( warningNotification );
      } else {
        this.removeWarningNotificationBanner();
      }
    }, this.subscriberName );

    this._state.subscribe( EVENT_NAMES.NEWS_NOTIFICATION, ( newsNotification: Notification ) => {
      if ( newsNotification ) {
        setTimeout( () => {
          this.dialog.open( NotificationsComponent, {
            data: {
              notification: newsNotification,
            },
          } );
        }, 2000 );
      }
    }, this.subscriberName );

    this.router.events
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( e => {
        if ( e instanceof NavigationEnd ) {
          // Logger.log( e );
          window.scrollTo( 0, 0 );
          this.checkRoute( e.url );
        }
      } );

    if ( this._state.globalVars.warningNotification ) {
      this.setupWarningNotificationBanner( this._state.globalVars.warningNotification );
    }
    if ( this.router.routerState.snapshot.root.queryParams.openDialog ) {
      setTimeout( () => {
        this.openDialog( this.router.routerState.snapshot.root.queryParams.openDialog );
      }, 2000 ); // give it two seconds so the page has time to load. also need a gate for when the dialog requires accounts to be fully loaded
    }

  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [
      EVENT_NAMES.EDITING_CHANGED,
      EVENT_NAMES.SUBSCRIPTION_CHANGED,
      EVENT_NAMES.WARNING_NOTIFICATION,
      EVENT_NAMES.NEWS_NOTIFICATION,
    ].join( ' | ' ), this.subscriberName );
    this.onDestroy.next();
  }

  checkRoute( url: string ) {
    this._state.globalVars.currentPageTitle = this._state.activeLinks[ url.split( '?' )[ 0 ] ] as PAGE_NAMES;
    if ( this._state.globalVars.currentPageTitle === PAGE_NAMES.PROFILE || this._state.globalVars.currentPageTitle === PAGE_NAMES.MARKET_INFORMATION_DASHBOARD ) {
    } else {
      this._state.globalVars?.allocWidget?.triggerRerenderOfDatatable();
      if ( this._state.globalVars.currentColumnGroup ) {
        this._state.globalVars.allocWidget?.goToColumnGroup( this._state.globalVars.currentColumnGroup, 50 );
      }
    }
  }

  setupAndRetrieveData() {

    if ( this._auth.authenticated() ) {
      if ( !this.firstRetrievalDone ) {
        if ( !this._state.globalVars.inWealthFluent ) {
          this._subscriptionDetailsUtil.initiateProductRetrieval();
        }

        const user = Util.getLoggedInUser( this._auth );
        const userPrefs = UsersUtil.checkPreferences( this._auth, 'marketInfo' );
        this._state.globalVars.numberOfObservations = userPrefs?.marketInfo?.numberOfObservations?.value ?? '60';

        this._subscriptionDetailsUtil.hasActiveSubscription().subscribe( {
          next: ( hasActiveSubscription ) => {
            if ( user && hasActiveSubscription ) {
              this.firstRetrievalDone = true;
              this.retrieveAccountData( user );
            } else {
              console.info( 'no user is logged in or their subscription is inactive. do not need to retrieve accounts' );
            }
          }, error: ( err ) => {
            console.error( err );
            this._state.globalVars.subscriptionInactive = true;
          },
        } );
      } else {
        this._accountManager.entryPoint();
      }
    }

  }

  private retrieveAccountData( user: any ) {
    const state = user.state || 'US';

    this._state.globalVars.initialLoadDone = false;
    this._gdService.getCurrentMortgageRates( state )
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( mortgageRatesResp: any ) => {
          this._state.globalVars.mortgageRates = mortgageRatesResp.data;
          this._accountManager.entryPoint();
        }, error: ( err ) => {
          console.error( err.err );
          // still need to get account and security data even if the mortgage rates error out
          this._accountManager.entryPoint();
        },
      } );
  }

  /**
   * this function will start the gathering of all data that is needed for some of the dialogs
   */
  private getDialogNeededData() {

    this._gdService.getCustomColumnSets().subscribe( ( response: any ) => {
      this._state.globalVars.userColumnSets.push.apply( this._state.globalVars.userColumnSets, response.data );
    } );

    this._revisionService.getRevisions()
      .pipe( takeUntil( this.onDestroy ) )
      .subscribe( {
        next: ( response: any ) => {
          // Logger.log( `revisions: ${response.data}` );
          this.revManagerUtil.userRevisions = response.data;
          this.revManagerUtil.userRevisions$.next( this.revManagerUtil.userRevisions );
        }, error: ( err ) => {
          console.error( err );
        },
      } );

  }

  private setupWarningNotificationBanner( wn: Notification ) {
    this.warningNotification = wn;
  }

  private removeWarningNotificationBanner() {
    this.warningNotification = undefined;
  }

  openNotification( n: Notification ) {
    this.dialog.open( NotificationsComponent, {
      data: {
        notification: n,
      },
    } );
  }

  /*
   * This function is called if a querystring parameter ('openDialog') is present in the router. It checks the parameter
   * value and determines what dialog to open
   * * possible value for dialogName (openDialog) are:
   * ['connect-accounts',
   'add-manual-accounts',
   'manage-connected-accounts',
   'manage-benchmark',
   'manage-overrides',
   'settings']
   */
  openDialog( dialogName: string ) {
    const self = this;
    if ( this.dialog.openDialogs.length === 0 ) {
      switch ( dialogName ) {
        case 'manage-overrides':
          self.openDialogDelayed( () => {
            if ( self.deviceIsMobile ) { // no mobile support for this yet
              this.openUnsupportedOnMobileAlertDialog();
            } else {
              this._overridesDialogUtil.openGlobalOverridesManager();
            }
          } );
          break;
        case 'settings':
          // this works in mobile as is for the most part
          this.dialog.open( SettingsComponent, {
            // width: '55vw',
            height: '50vh',
            panelClass: 'settings-panel',
            hasBackdrop: false,
          } );
          break;
        default:
          // parameter is not yet in use
          break;
      }
    }
  }

  /*
   * This function is for opening a dialog after accounts are loaded. it first checks to see if the accounts have been pulled.
   * If not, it creates a subscription to the account.manager.refresh.complete event and calls the function to open
   * the dialog once the event fires. The it removes the subscription
   */
  openDialogDelayed( func: Function ) {
    if ( this._state.globalVars.firstAccountPullComplete ) {
      func();
    } else {
      const subName = 'openDialogDelayedSub';
      this._state.subscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, () => {
        func();
        this._state.unsubscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, subName );
      }, subName );
    }
  }

  openUnsupportedOnMobileAlertDialog() {
    this._alertService.showAlert( {
      title: 'Not Available on Mobile',
      message: 'This feature is only available through a desktop browser at this time. Please try on a desktop browser. Thanks! ',
    } );
  }

}
