import { Injectable } from '@angular/core';
import { Quote, RiskReturn, VixSnapshot } from './dataInterfaces';
import { GlobalState } from '../global.state';
import { takeUntil } from 'rxjs/operators';
import { PricingService } from '../globalData';
import { Observable, Subject } from 'rxjs';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { UsersUtil } from './users.util';
import { Auth } from '../auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class MarketInfoUtil {

  private readonly onDestroy = new Subject<void>();

  static readonly SBBI_URL = 'https://www.cfainstitute.org/-/media/documents/book/rf-publication/2021/sbbi-summary-edition-2021.pdf';

  customVix: number;
  loadingCurrentVix: boolean = true;
  currentVixError: boolean = false;

  marketInfoSettings: any = {};
  currentVixQuote: Quote;
  snapshot: VixSnapshot;

  static readonly defaultLongTermRiskPremium: number = 7.7;
  static readonly defaultLongTermStandardDeviation: number = 17.2;
  static readonly defaultLongTermObservations: string = '108';
  static readonly defaultShortTermObservations: string = '60';

  longTermStandardDeviation: number;
  shortTermStandardDeviation: number;
  longTermRiskPremium: number;
  shortTermRiskPremium: number;

  calculatedShortTermRiskPremium: number;

  constructor( private _state: GlobalState, private _pricingService: PricingService, private _auth: Auth ) {

    const userPrefs = UsersUtil.checkPreferences( this._auth, 'marketInfo' );
    this.marketInfoSettings = userPrefs?.marketInfo ?? {};
    this.setLocalMarketInfoSettings();

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_marketInfoUtil' ] = this;
    }

  }

  marketSettingsChanged( settings: any ) {
    this.marketInfoSettings = settings;
    this.setLocalMarketInfoSettings();
  }

  // TODO: need to call this from somewhere in the pages component maybe to get it before settings can be opened
  getCurrentVix(): Observable<any> {
    this.loadingCurrentVix = true;
    this.currentVixError = false;
    return new Observable( ( observer ) => {
      this.customVix = this.marketInfoSettings?.shortTermMarketVolatility?.value; // if it isn't set, this should just be undefined
      this.currentVixError = false;
      this._pricingService.getPrice( 'VIX', 'INDX' )
        .pipe( takeUntil( this.onDestroy ) )
        .subscribe( {
          next: ( resp: any ) => {
            if ( !resp?.data?.price ) {
              // no price returned
              this.currentVixError = true;
            } else {
              this.currentVixQuote = resp?.data;
              const vixToUse = this.customVix ?? this.currentVixQuote?.price;
              const diffFromLTAV: number = vixToUse - this.longTermStandardDeviation;
              const diffPercent: number = diffFromLTAV / this.longTermStandardDeviation;
              this.snapshot = {
                current: vixToUse,
                previousClose: this.currentVixQuote?.lastClose,
                change: Math.abs( vixToUse - this.currentVixQuote?.lastClose ),
                percentChange: Math.abs( ( vixToUse - this.currentVixQuote?.lastClose ) / this.currentVixQuote?.lastClose ),
                diffFromLTAV: Math.abs( diffFromLTAV ),
                percentDiffFromLTAV: Math.abs( diffPercent ),
                currentColor: MarketInfoUtil.getColor( this.currentVixQuote?.price ),
                icon: vixToUse - this.currentVixQuote?.lastClose >= 0 ? faCaretUp : faCaretDown,
                LTAVicon: vixToUse - this.longTermStandardDeviation >= 0 ? faCaretUp : faCaretDown,
                diffDirection: diffFromLTAV > 0 ? 'up' : diffFromLTAV < 0 ? 'down' : '',
              };
            }
            this.loadingCurrentVix = false;
            this.setLocalMarketInfoSettings();
            observer.complete();
            // this.checkForBothDataPieces();
          }, error: ( err ) => {
            console.error( err );
            this.currentVixError = true;
            this.loadingCurrentVix = false;
          },
        } );
    } );
  }

  setLocalMarketInfoSettings() {
    // LONG TERM MARKET VOL
    if ( this.marketInfoSettings?.longTermMarketVolatility?.value ) {
      this.longTermStandardDeviation = this.marketInfoSettings?.longTermMarketVolatility?.value;
    } else {
      this.longTermStandardDeviation = MarketInfoUtil.defaultLongTermStandardDeviation;
    }

    // LONG TERM RISK PREMIUM
    if ( this.marketInfoSettings?.longTermMarketRiskPremium?.value ) {
      this.longTermRiskPremium = this.marketInfoSettings?.longTermMarketRiskPremium?.value;
    } else {
      this.longTermRiskPremium = MarketInfoUtil.defaultLongTermRiskPremium;
    }

    // SHORT TERM MARKET VOL
    if ( this.marketInfoSettings?.shortTermMarketVolatility?.value ) {
      this.shortTermStandardDeviation = this.marketInfoSettings?.shortTermMarketVolatility.value;
    } else {
      this.shortTermStandardDeviation = this.currentVixQuote?.price;
    }

    // SHORT TERM RISK PREMIUM
    if ( this.marketInfoSettings?.shortTermMarketRiskPremium?.value ) {
      this.shortTermRiskPremium = this.marketInfoSettings?.shortTermMarketRiskPremium?.value;
    } else {
      this.shortTermRiskPremium = this.calcShortTermRiskPremium();
    }

    this.calculatedShortTermRiskPremium = this.calcShortTermRiskPremium();

  }

  calcShortTermRiskPremium() {
    return parseFloat( ( ( this.longTermRiskPremium / Math.pow( this.longTermStandardDeviation, 2 ) ) * Math.pow( this.shortTermStandardDeviation, 2 ) ).toFixed( 2 ) );
  }


  static stockRiskReturnsHaveError( riskReturnsArray: RiskReturn[] ) {
    for ( const rr of riskReturnsArray ) {
      if ( rr.identifier === 'STOCKS' && (
        rr.expectedReturn === undefined ||
        rr.updatedStandardDeviation === undefined ||
        rr.expectedSharpeRatio === undefined ||
        rr.longTermUpdatedStandardDeviation === undefined ) ) {
        return true;
      }
    }
    return false;
  }

  static bondRiskReturnsHaveError( riskReturnsArray: RiskReturn[] ) {
    for ( const rr of riskReturnsArray ) {
      if ( rr.identifier === 'BONDS' && (
        rr.expectedReturn === undefined ||
        rr.updatedStandardDeviation === undefined ||
        rr.annualizedStandardDeviation === undefined ||
        rr.expectedSharpeRatio === undefined ||
        rr.longTermExpectedReturn === undefined ||
        rr.longTermAnnualizedStandardDeviation === undefined ) ) {
        return true;
      }
    }
    return false;
  }

  static getColor( value: number ) {
    if ( 0 <= value && value <= 10 ) {
      return '#49a1e0';
    } else if ( 10 < value && value <= 13 ) {
      return '#207bbc';
    } else if ( 13 < value && value <= 24 ) {
      return '#ffa139';
    } else if ( 24 < value && value <= 48 ) {
      return '#ff452b';
    } else if ( value > 48 ) {
      return '#993333';
    }
  }
}
