import { Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { GlobalState } from '../../../../../../../global.state';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faSlidersH } from '@fortawesome/pro-light-svg-icons/faSlidersH';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GlobalOverrideItem } from '../../../../../../../utils/dataInterfaces';
import { filter } from 'rxjs/operators';
import { SettingsComponent } from '../../../../../../../pages/modals/settings';
import { OverridesDialogUtil } from '../../../../../../../utils/overiddesDialog.util';
import { environment } from '../../../../../../../../environments/environment';
import { EVENT_NAMES } from '../../../../../../../utils/enums';
import { WithWorkspacePermission } from '../../../../../../../shared/workspace-permission';
import { faBell } from '@fortawesome/pro-light-svg-icons/faBell';
import { OverridesUtil } from '../../../../../../../utils/overrides.util';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { WealthFluentMessageService } from '../../../../../../services';


@Component( {
  selector: 'rip-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: [ '../baMenuItem/baMenuItem.scss', '../../../../baSidebar.scss', './preferences.component.scss' ],
} )
export class PreferencesComponent extends WithWorkspacePermission implements OnDestroy {

  private wfMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  @Input() sidebarCollapsed: boolean = true;
  @Input() menuHeight: number;
  @Output() itemHover: EventEmitter<any> = new EventEmitter<any>();
  loading: boolean = true;

  faCog: IconDefinition = faCog;
  faChartLine: IconDefinition = faChartLine;
  faSlidersH: IconDefinition = faSlidersH;
  faDatabase: IconDefinition = faDatabase;

  editing: boolean = false;
  subscriberName: string = 'preferencesComponent';
  currentPage: string = '';
  dataQualityBadge: number = 0;
  inWealthFluent: boolean = false;

  constructor( private _state: GlobalState,
               private _router: Router,
               public dialog: MatDialog,
               private _overridesUtil: OverridesUtil,
               private _overridesDialogUtil: OverridesDialogUtil ) {
    super();
    this.inWealthFluent = this._state.globalVars.inWealthFluent;
    this.currentPage = this._state.activeLinks[ this._router.url ];

    _router.events.pipe(
      filter( e => e instanceof NavigationEnd ),
    ).subscribe( ( e: NavigationEnd ) => {
      this.currentPage = this._state.activeLinks[ e.url ];
    } );


    this._state.subscribe( EVENT_NAMES.EDITING_CHANGED, ( editingState ) => {
      this.editing = editingState;
    }, this.subscriberName );

    this._overridesUtil.dataCheck.subscribe( {
      next: () => {
        this.loading = false;
        this.dataQualityBadge = this._overridesUtil.positionsWithMissingData?.length ?? 0;
      }, error: ( err ) => {
        console.error( err );
      },
    } );

    if ( this._state.globalVars.firstAccountPullComplete ) {
      this.loading = false;
    }

    if ( environment.env !== 'prod' ) {
      window[ `ripsaw_${  this.subscriberName}` ] = this;
    }
  }

  onHoverItem( $event ): void {
    this.itemHover.emit( $event );
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( [ EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, EVENT_NAMES.EDITING_CHANGED ].join( ' | ' ), this.subscriberName );
  }

  openSettings() {
    if ( this.dialog.openDialogs.length === 0 ) {
      this.dialog.open( SettingsComponent, {
        // width: '55vw',
        height: '50vh',
        panelClass: 'settings-panel',
        hasBackdrop: false,
      } );
    }
    this.wfMessageService.hasNavigatedSinceLastToldToByWF = true;
  }

  openGlobalOverridesManager( goi?: GlobalOverrideItem ) {
    this._overridesDialogUtil.openGlobalOverridesManager( goi );
  }

  protected readonly faBell = faBell;
}
