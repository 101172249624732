<ng-template #formField let-key="key">
    <mat-form-field [ngClass]="{
    'loan-field-hint': key.isLoanField,
        'override-in-place': key.fieldHasOverride,
        'provider-value': !key.fieldHasOverride,
        'missing-data': key.fieldHasMissingData
        }">

        <!-- for decimal inputs -->
        <input matInput #decimalInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="decimal_{{key.key}}"
               [errorStateMatcher]="matcher"
               (change)="onInput(decimalInput, key.key, 'decimal')"
               placeholder="{{key?.colDef?.name}} {{ key.key === 'loan_term' ? '(Years)' : '' }}"
               *ngIf="key.inputType === 'decimal'">
        <!-- for percent inputs -->
        <input matInput #percentInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="percent_{{key.key}}"
               [errorStateMatcher]="matcher"
               (change)="onInput(percentInput, key.key, 'percent')"
               placeholder="{{key?.colDef?.name}} (%)"
               *ngIf="key.inputType === 'percent'">
        <!-- for currency inputs -->
        <input matInput #currencyInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="currency_{{key.key}}"
               [errorStateMatcher]="matcher"
               (change)="onInput(currencyInput, key.key, 'currency')"
               placeholder="{{key?.colDef?.name}} ($)"
               *ngIf="key.inputType === 'currency'">

        <!-- for text inputs -->
        <input matInput #textInput
               name="{{key.key}}"
               [formControl]="$any(form).controls[key.key]"
               id="text_{{key.key}}"
               [errorStateMatcher]="matcher"
               placeholder="{{key?.colDef?.name}}"
               *ngIf="key.inputType === 'string'">
        <!-- for select inputs -->
        <mat-select *ngIf="key.inputType === 'select'" #selectBox
                    panelClass="max-select-box-height"
                    [formControl]="$any(form).controls[key.key]"
                    placeholder="{{key?.colDef?.name}}"
                    (selectionChange)="onSelectionChange(selectBox, key.key)">
            <!--<mat-option *ngIf="!oHelpers.getSelectOptions(key).includes(form.controls[key].value)"
                        value="{{form.controls[key].value}}">
              {{form.controls[key].value}}
            </mat-option>-->
            <mat-option *ngFor="let option of key.selectOptions" value="{{option}}">
                {{option}}
            </mat-option>
        </mat-select>
        <!-- for date inputs -->
        <div *ngIf="key.inputType === 'date'" class="date-picker-container">
            <input matInput #dateInput
                   name="{{key.key}}"
                   [formControl]="$any(form).controls[key.key]"
                   id="date_{{key.key}}"
                   [errorStateMatcher]="matcher"
                   placeholder="{{key?.colDef?.longName || key?.colDef?.name}}"
                   [matDatepicker]="datepicker"
                   (dateChange)="dateChanged(dateInput, key.key)">
            <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker></mat-datepicker>
        </div>

        <!-- Using hint for showing override -->
        <mat-hint *ngIf="key.fieldHasOverride">
            <fa-icon [icon]="faUndoAlt"
                             (click)="removeSingleOverrideAndSave( key.key )"
                             class="remove-override-button"
                             title="Reset To Provider Value"
                             *ngIf="key.key !== 'price' && key.key !== 'quantity'">
            </fa-icon>
            <!-- for price or quantity -->
            <span *ngIf="(key.key === 'price' || key.key === 'quantity') && key.isLocalProxyOverride">
                Non Proxy Value: {{ key.formattedOldOverrideValue }}
            </span>

            <!-- not price or quantity -->
            <span *ngIf="(key.key !== 'price' && key.key !== 'quantity') || !key.isLocalProxyOverride">
                Provider Value: {{ key.formattedOldOverrideValue || '_____' }}
            </span>
        </mat-hint>

        <mat-hint *ngIf="key.isLoanField && (position && !position[key.key] || position[key.key] === '' )">
            Entering Data in this field will help us calculate a market value for this loan
        </mat-hint>
        <mat-hint
                *ngIf="key.key === 'current_market_rate' && !key.fieldHasOverride && position?.ticker_name === 'Mortgage'">
            Remaining maturity matching market rate (From Zillow)
        </mat-hint>
    </mat-form-field>
</ng-template>

<!-- Start dialog -->
<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle id="override-dialog-panel-header"> {{ modalTitle }} </h3>
<mat-divider class="modal-header-divider"></mat-divider>
<div mat-dialog-content class="modal-body override-modal-body xl-dialog" [nbSpinner]="loadingOverrides">
    <div *ngIf="!loadingOverrides">
        <p *ngIf="grid">This panel is used for overriding values for this security that you know to be incorrect or
            missing.
        </p>

        <div style="display: flex;" id="override-modal-btn-row">
            <span>
                <button mat-stroked-button type="button" (click)="removeAllOverrides()" [disabled]="disableResetAll"
                        *ngIf="saveToOverrides"
                        tabIndex="-1">
                    Reset All Overrides
                </button>
            </span>
            <span style="flex-grow: 2;">
                <button mat-stroked-button type="button" (click)="openInvestmentSelector()" *ngIf="saveToOverrides"
                        tabIndex="-1">
                    {{ position?.overridden_ticker ? 'Change Proxy' : 'Pick Proxy' }}
                </button>
                <fa-icon [icon]="faInfoCircle" class="override-info-icon"
                         [nbPopover]="proxyInfoPopoverTemplate"
                         [nbPopoverTrigger]="popoverTrigger"
                         nbPopoverClass="rip-popover"
                         *ngIf="saveToOverrides">
                </fa-icon>
                <span *ngIf="position?.overridden_ticker">
                  {{ tempProxy ? tempProxy?.ticker : position?.ticker }} as proxy for {{ position?.overridden_ticker }}
                </span>
            </span>
            <span class="legend-container right">
              <rip-override-legend></rip-override-legend>
            </span>
        </div>

        <form autocomplete="off" [formGroup]="form">
            <div class="override-form-container">
                <!-- Using a material vertical stepper to make groupings for the override fields so the form isn't just
                one long list -->
                <mat-vertical-stepper *ngIf="!loadingOverrides">
                    <!-- Overriding the icons so there aren't numbers which would normally be used to denote a flow -->
                    <ng-template matStepperIcon="done">
                        <mat-icon>dehaze</mat-icon>
                    </ng-template>
                    <ng-template matStepperIcon="edit">
                        <mat-icon>dehaze</mat-icon>
                    </ng-template>
                    <!-- number is the active step icon type -->
                    <ng-template matStepperIcon="number">
                        <mat-icon>create</mat-icon>
                    </ng-template>

                    <!-- Step for local form inputs (price, quantity and value(disabled)), not part of global override-->
                    <mat-step *ngIf="this.localOverrides" [completed]="true">
                        <!-- label the step header with the set's label -->
                        <ng-template matStepLabel>
                            {{ localKeySet?.label }}
                        </ng-template>


                        <!-- Make a form field for each key in the set -->
                        <div *ngFor="let key of localKeySet?.keys" class="override-form-field-container">
                            <div class="override-form-field-container">
                                <ng-container *ngTemplateOutlet="formField; context:{'key': key}">
                                </ng-container>
                            </div>

                        </div>
                        <div *ngFor="let group of localKeySet?.groups">
                            <div *ngIf="group.keys.length > 0">

                                <div class="group-header">
                                    <span [ngClass]="{
                                          'missing-data': group.groupHasMissingData
                                          }">
                                        {{ group.label }}
                                    </span>
                                </div>
                                <div *ngFor="let key of group.keys" class="override-form-field-container">
                                    <ng-container *ngTemplateOutlet="formField; context:{'key': key}">
                                    </ng-container>
                                </div>
                                <div *ngIf="group?.label === 'Loan Fields'" class="zillow-rate-picker">
                                    <rip-zillow-rate-picker
                                            (rateChosen)="chooseRate($event)"></rip-zillow-rate-picker>
                                </div>
                                <div *ngIf="group?.group?.footnote" class="footnote">
                                    {{ group?.group?.footnote }}
                                </div>
                            </div>
                        </div>
                    </mat-step>
                    <!-- Make a step for each key set -->
                    <mat-step *ngFor="let set of globalKeySets" [completed]="true">
                        <!-- label the step header with the set's label -->
                        <ng-template matStepLabel>
                            {{ set.label }} <span
                                *ngIf="!positionIsCURUSD">(Applies to this security in all accounts)</span>
                        </ng-template>


                        <!-- Make a form field for each key in the set -->
                        <div *ngFor="let key of set.keys" class="override-form-field-container">
                            <div class="override-form-field-container">
                                <ng-container *ngTemplateOutlet="formField; context:{'key': key}">
                                </ng-container>
                            </div>
                        </div>

                        <div *ngFor="let group of set.groups">
                            <div *ngIf="group.keys.length > 0">

                                <div class="group-header">
                                <span [ngClass]="{
                                      'missing-data': group.groupHasMissingData
                                      }">
                                    {{ group.label }}
                                </span>
                                </div>
                                <div *ngFor="let key of group.keys" class="override-form-field-container">
                                    <ng-container *ngTemplateOutlet="formField; context:{'key': key}">
                                    </ng-container>
                                </div>
                                <div *ngIf="group?.group?.footnote" class="footnote">
                                    {{ group?.group?.footnote }}
                                </div>
                            </div>
                        </div>
                    </mat-step>

                </mat-vertical-stepper>

            </div>
        </form>
        <div class="legend-container right" style="width: 100%;">
            <rip-override-legend></rip-override-legend>
        </div>
    </div>
</div>
<mat-divider class="modal-footer-divider"></mat-divider>

<div mat-dialog-actions>
    <button mat-button (click)="close()" type="button">{{ unsavedChanges ? 'Cancel' : 'Close' }}</button>

    <rip-progress-bar-button (onClick)="submit()" [options]="saveButtonOptions" [disabled]="!unsavedChanges">

    </rip-progress-bar-button>
    <button mat-button (click)="removeNewSecurity()" type="button" color="warn" *ngIf="origPosition?.newSecurity">
        Remove
    </button>
</div>


<ng-template #proxyInfoPopoverTemplate>
    <nb-card class="popover-card">
        <nb-card-header class="popover-title">
            Security Override Proxy
        </nb-card-header>
        <nb-card-body>
            <div class="popover-content">
                You can choose a proxy for any security. All the fields in the security will be overridden with those
                of the proxy. This allows you to track the proxy instead (or because your security is not public and
                it's details cannot be retrieved). This feature can also be used to get updated prices for a mutual fund
                during the day by using an ETF as a proxy. The price of the proxy will be used and the quantity will be
                overridden using the simple
                <br>
                <p>Quantity = Value / Price </p> formula to handle the translation from your holding to the proxy. This
                allows {{ appName }} to update the price and quantity throughout the day using this new quantity. The quantity
                is always calculated again when your accounts are loaded from our aggregator so any changes you make to
                the security at your institution will be reflected
            </div>
        </nb-card-body>
    </nb-card>
</ng-template>
