import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AuthGuard, ProfileAuthGuard } from './auth-guard.service';
import { Auth } from './auth.service';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import {
  PercentPipe,
  CurrencyPipe,
  DecimalPipe,
  DatePipe,
} from '@angular/common';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { AppState } from './app.service';
import { GlobalState } from './global.state';
import { NgaModule } from './theme/nga.module';
import { PagesModule } from './pages/pages.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { environment } from '../environments/environment';
import { GlobalDataService } from './globalData';
import { AccountManager } from './utils/accountManager';
import { AlertModalService } from './reusableWidgets/alertModal';
import { PricingService } from './globalData';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  NbLayoutModule,
  NbMenuModule,
  NbMenuService,
  NbThemeModule,
  NbTooltipModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { MatDividerModule } from '@angular/material/divider';
import { UsersUtil } from './utils/users.util';
import { faOptimizer, faUmbrellaSlash } from './custom-icons';
import { LayoutModule } from '@angular/cdk/layout';
import { AdminService } from './globalData/admin.service';
import { UserStatsState } from './utils/userStats.state';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ApiModule } from './api';
import { ApiInterceptor } from './api.interceptor';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { appEffects, appReducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { WorkspacesInterceptor } from './workspaces.interceptor';
import { UserAnalyticsService } from './utils/user-analytics.service';

// Application wide providers
const APP_PROVIDERS = [
  AppState,
  GlobalState,
  AuthGuard,
  ProfileAuthGuard,
  Auth,
  PercentPipe,
  CurrencyPipe,
  DecimalPipe,
  DatePipe,
  HttpClient,
  GlobalDataService,
  AccountManager,
  AlertModalService,
  PricingService,
  NbMenuService,
  UsersUtil,
  AdminService,
  UserStatsState,
  MessageService,
  UserAnalyticsService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: WorkspacesInterceptor,
    multi: true,
  },
];

export function getToken() {
  return sessionStorage.getItem('id_token');
}

const jwtConf: JwtModuleOptions = {
  config: {
    tokenGetter: getToken,
    allowedDomains: [environment.ripsawAPIBaseUrl],
  },
};

const APP_MODULES = [
  routing,
  BrowserModule,
  BrowserAnimationsModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  NgaModule.forRoot(),
  PagesModule,
  NgxDatatableModule,
  MatTooltipModule,
  MatStepperModule,
  MatDatepickerModule,
  MatMomentDateModule,
  FontAwesomeModule,
  MatDialogModule,
  NgaModule,
  MatButtonModule,
  MatCheckboxModule,
  NbLayoutModule,
  NbEvaIconsModule,
  NbTooltipModule,
  NbMenuModule.forRoot(),
  NbThemeModule.forRoot({ name: 'default' }),
  JwtModule.forRoot(jwtConf),
  TranslateModule.forRoot(),
  MatDividerModule,
  LayoutModule,
  NgIdleKeepaliveModule.forRoot(),
  ToastModule,
  ApiModule.forRoot({ rootUrl: environment.ripsawAPIBaseUrl }),
  StoreModule.forRoot(appReducers),
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    logOnly: environment.production,
  }),
  EffectsModule.forRoot(appEffects),
];

/*export type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void,
};*/

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  imports: [...APP_MODULES],
  exports: [RouterModule],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    APP_PROVIDERS,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faOptimizer);
    library.addIcons(faUmbrellaSlash);
  }
}
