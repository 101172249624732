import { Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faUniversity } from '@fortawesome/pro-light-svg-icons/faUniversity';
import { GlobalState } from '../../../../../../../global.state';
import { faBalanceScale, faChartNetwork, faRepeat } from '@fortawesome/pro-light-svg-icons';
import { faBalanceScaleRight } from '@fortawesome/free-solid-svg-icons/faBalanceScaleRight';
import { faLandmark } from '@fortawesome/pro-light-svg-icons/faLandmark';
import { faListAlt } from '@fortawesome/pro-light-svg-icons/faListAlt';
import { faUserChart } from '@fortawesome/pro-light-svg-icons/faUserChart';
import { faChartLine } from '@fortawesome/pro-light-svg-icons/faChartLine';
import { faChartLineDown } from '@fortawesome/pro-regular-svg-icons/faChartLineDown';
import { faAnalytics } from '@fortawesome/pro-light-svg-icons/faAnalytics';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { faOptimizer } from '../../../../../../../custom-icons';
import { faAwardSimple } from '@fortawesome/pro-light-svg-icons/faAwardSimple';
import { EVENT_NAMES, PAGE_NAMES } from '../../../../../../../utils/enums';
import { hasAnyPermissionFn, WithWorkspacePermission } from '../../../../../../../shared/workspace-permission';
import { Subject } from 'rxjs';
import { AppStoreService } from '../../../../../../../store';
import { WorkspacePermissionNameStore } from '../../../../../../../store/workspace';
import { IconDefinition } from '@fortawesome/free-regular-svg-icons';
import { WealthFluentMessageService } from '../../../../../../services';

@Component( {
  selector: 'rip-pages-menu',
  templateUrl: './pagesMenu.component.html',
  styleUrls: [ '../pagesMenu/pagesMenu.scss', '../../../../baSidebar.scss' ],
} )

export class PagesMenuComponent extends WithWorkspacePermission implements OnInit, OnDestroy {
  private destroyed$$: Subject<void> = new Subject<void>();
  private wfMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );

  PAGE_NAMES = PAGE_NAMES;

  faList: IconDefinition = faList; // balance sheet
  faUniversity: IconDefinition = faUniversity; // old accounts
  faLandmark: IconDefinition = faLandmark; // accounts
  faBalanceScale: IconDefinition = faBalanceScale; // start a revision
  faBalanceScaleRight: IconDefinition = faBalanceScaleRight;
  // faBalanceScaleLeft = faBalanceScaleLeft;
  faListAlt: IconDefinition = faListAlt; // holdings
  // faWandMagic = faWandMagic;
  faChartNetwork: IconDefinition = faChartNetwork; // old optimizer icon in fontawesome v5. now custom below as faOptimizer
  faRepeat: IconDefinition = faRepeat; // transactions

  faChartLine: IconDefinition = faChartLine;
  faChartLineDown: IconDefinition = faChartLineDown; //
  faAnalytics: IconDefinition = faAnalytics; // market info dashboard
  faUserChart: IconDefinition = faUserChart; // plan/benchmark
  faOptimizer: IconDefinition = faOptimizer; // start an optimization

  faAwardSimple: IconDefinition = faAwardSimple; // goals page

  @Input() sidebarCollapsed: boolean = true;

  @Output() itemHover: EventEmitter<any> = new EventEmitter<any>();

  editing: boolean = false;
  loading: boolean = true;

  subscriberName: string = 'pagesMenuComponent';

  currentPageName: PAGE_NAMES;

  inWealthFluent: boolean = false;

  constructor( private _state: GlobalState,
               private _router: Router,
               public dialog: MatDialog,
               private appStoreService: AppStoreService ) {
    super();

    this._state.subscribe( EVENT_NAMES.EDITING_CHANGED, ( editingState: boolean ) => {
      this.editing = editingState;
    }, this.subscriberName );
    this._state.subscribe( EVENT_NAMES.ACCOUNT_MANAGER_REFRESH_COMPLETE, () => {
      this.loading = false;
    }, this.subscriberName );

    this.currentPageName = this._state.activeLinks[ this._router.url.split( '?' )[ 0 ] ];
    this._state.currentPageName = this.currentPageName;

    this._router.events.pipe(
      filter( e => e instanceof NavigationEnd ),
    ).subscribe( ( e: NavigationEnd ) => {
      this.currentPageName = this._state.activeLinks[ e.url.split( '?' )[ 0 ] ];
      this._state.currentPageName = this.currentPageName;
    } );

    this.inWealthFluent = this._state.globalVars.inWealthFluent;

    if ( environment.env !== 'prod' ) {
      window[ 'ripsaw_pagesMenu' ] = this;
    }
  }

  ngOnInit(): void {
    this.observeForRedirectIfNoPermissions();
  }

  ngOnDestroy(): void {
    this._state.unsubscribe( EVENT_NAMES.EDITING_CHANGED, this.subscriberName );
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onHoverItem( $event ): void {
    this.itemHover.emit( $event );
  }

  // goToBalanceSheet() {
  //   this._router.navigate( [ 'pages/balanceSheet' ] );
  // }

  goToAccounts() {
    this._router.navigate( [ 'pages/accounts' ] );
    this.wfMessageService.hasNavigatedSinceLastToldToByWF = true;
  }

  goToHoldings() {
    this._router.navigate( [ 'pages/holdings' ] );
    this.wfMessageService.hasNavigatedSinceLastToldToByWF = true;
  }

  // goToMarketInfoDashboard() {
  //   this._router.navigate( [ 'pages/market-information-dashboard' ] );
  // }

  goToTransactions() {
    this._router.navigate( [ 'pages/transactions' ] );
    this.wfMessageService.hasNavigatedSinceLastToldToByWF = true;

  }

  /*
   * navigate to the benchmark page
   **/
  goToBenchmarkPage() {
    this._router.navigate( [ '/pages/benchmark' ] ).then( () => {
      // nothing needed to be done here
    } );
    this.wfMessageService.hasNavigatedSinceLastToldToByWF = true;
  }

  // goToGoalsPage() {
  //   this._router.navigate( [ '/pages/goals-dashboard' ] ).then( () => {
  //     // nothing needed to be done here
  //   } );
  // }
  //
  // startRevision() {
  //   if ( !this._router.url.includes( 'accounts' ) ) {
  //     this._router.navigate( [ 'pages/accounts' ], { queryParams: { startRev: true } } );
  //   } else {
  //     this._state.notifyDataChanged( EVENT_NAMES.START_REVISION, null );
  //   }
  // }
  //
  // startRebalance() {
  //   if ( !this._router.url.includes( 'accounts' ) ) {
  //     this._router.navigate( [ 'pages/accounts' ], { queryParams: { startRev: 'start.rebalance' } } );
  //   } else {
  //     this._state.notifyDataChanged( EVENT_NAMES.START_REVISION, 'start.rebalance' );
  //   }
  // }
  //
  // startTacticalAllocation() {
  //   if ( !this._router.url.includes( 'accounts' ) ) {
  //     this._router.navigate( [ 'pages/accounts' ], { queryParams: { startRev: 'start.tacticalAllocation' } } );
  //   } else {
  //     this._state.notifyDataChanged( EVENT_NAMES.START_REVISION, 'start.tacticalAllocation' );
  //   }
  // }

  private observeForRedirectIfNoPermissions(): void {
    const permsByPath: { [Path in string]: WorkspacePermissionNameStore[] } = {
      'pages/transactions': [ this.perm.OWNER, this.perm.TRANSACTIONS_VIEW ],
    };

    this.appStoreService.loadedWorkspacePermissions$
      .pipe(
        filter( Boolean ),
        takeUntil( this.destroyed$$ ),
      )
      .subscribe( allPerms => {
        const curUrl = this._router.url;
        const path = Object
          .keys( permsByPath )
          .find( p => curUrl.includes( p ) );

        if ( path ) {
          const perms = permsByPath[ path ];
          const hasPermissions = hasAnyPermissionFn( allPerms, perms );

          if ( !hasPermissions ) {
            this._router.navigate( [ '/' ] );
          }
        }
      } );
  }

}
