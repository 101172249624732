import { commonEnvironment } from './environment.common';

export const environment = {
  production: true,
  env: 'qc',
  version: '4.1.0.2278',
  debugging: false,
  appName: 'WealthFluent QC',
  ripsawAPIBaseUrl: 'https://qc-api.ripsaw.co',
  common: commonEnvironment,
  stripePublishableKey: 'pk_test_qMchAbAaBV6SLIvB9B5gSA2A',
  yodleeFastLinkUrl: 'https://fl4.preprod.yodlee.com/authenticate/development-246/fastlink/?channelAppName=tieredpreprod',
  finGoalFastLinkUrl: 'https://finapp.fingoalchannelstage.yodlee.com/authenticate/ripsaw-development/fastlink?channelAppName=fingoalchannel',
  heap_io_app_id: '552367347',
  wealthFluentOrigin: 'https://qc.wealthfluent.com',
};
