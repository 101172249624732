<ul class="al-sidebar-list" ngaBaSlimScroll [style.padding-top]="0">
<!--  <li class="al-sidebar-list-item" *ngIf="!inWealthFluent"-->
<!--      [ngClass]="{ 'active-page': currentPageName === PAGE_NAMES.MARKET_INFORMATION_DASHBOARD}">-->
<!--    <a class="al-sidebar-list-link" (click)="goToMarketInfoDashboard()" (mouseenter)="onHoverItem($event)"-->
<!--       matTooltip="{{PAGE_NAMES.MARKET_INFORMATION_DASHBOARD}}"-->
<!--       matTooltipClass="mat-tooltip-custom"-->
<!--       matTooltipPosition="right">-->
<!--      <fa-icon id="market-data-popover-icon" [icon]="faAnalytics" [fixedWidth]="true"-->
<!--               size="2x"></fa-icon>-->

<!--      <span translate>{{ PAGE_NAMES.MARKET_INFORMATION_DASHBOARD }}</span>-->
<!--    </a>-->
<!--  </li>-->
<!--  <li class="al-sidebar-list-item" *ngIf="!inWealthFluent"-->
<!--      [ngClass]="{ 'active-page': currentPageName === PAGE_NAMES.BALANCE_SHEET}">-->
<!--    <a class="al-sidebar-list-link" (click)="goToBalanceSheet()" (mouseenter)="onHoverItem($event)"-->
<!--       matTooltip="{{PAGE_NAMES.BALANCE_SHEET}}: this is your home base where you can see all of your accounts and the basics of where your current funds are compared to your benchmark"-->
<!--       matTooltipClass="mat-tooltip-custom"-->
<!--       matTooltipPosition="right">-->
<!--      <fa-icon [icon]="faList" size="2x" [fixedWidth]="true"></fa-icon>-->
<!--      <span translate>{{ PAGE_NAMES.BALANCE_SHEET }}</span>-->
<!--    </a>-->
<!--  </li>-->
  <li class="al-sidebar-list-item"
      [ngClass]="{ 'active-page': currentPageName === PAGE_NAMES.ACCOUNTS}">
    <a class="al-sidebar-list-link" (click)="goToAccounts()" (mouseenter)="onHoverItem($event)"
       matTooltip="{{PAGE_NAMES.ACCOUNTS}}"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right">
      <fa-icon [icon]="faChartNetwork" size="2x" [fixedWidth]="true"></fa-icon>
      <!--            <fa-icon [icon]="faLandmark" size="2x" [fixedWidth]="true"></fa-icon>-->
      <span translate>{{ PAGE_NAMES.ACCOUNTS }}</span>
    </a>
  </li>
  <li class="al-sidebar-list-item"
      [ngClass]="{ 'active-page': currentPageName === PAGE_NAMES.HOLDINGS}">
    <a class="al-sidebar-list-link" (click)="goToHoldings()" (mouseenter)="onHoverItem($event)"
       matTooltip="{{PAGE_NAMES.HOLDINGS}}"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right">
      <fa-icon [icon]="faListAlt" size="2x" [fixedWidth]="true"></fa-icon>
      <span translate>{{ PAGE_NAMES.HOLDINGS }}</span>
    </a>
  </li>
  <li *ripPerm="[perm.OWNER, perm.TRANSACTIONS_VIEW]"
      class="al-sidebar-list-item"
      [ngClass]="{ 'active-page': currentPageName === PAGE_NAMES.TRANSACTIONS}">
    <a class="al-sidebar-list-link" (click)="goToTransactions()" (mouseenter)="onHoverItem($event)"
       matTooltip="{{PAGE_NAMES.TRANSACTIONS}}"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right">
      <fa-icon [icon]="faRepeat" size="2x" [fixedWidth]="true"></fa-icon>
      <span translate>{{ PAGE_NAMES.TRANSACTIONS }}</span>
    </a>
  </li>
  <li class="al-sidebar-list-item" *ngIf="!inWealthFluent"
      [ngClass]="{ 'active-page': currentPageName === PAGE_NAMES.PLAN_BENCHMARK}">
    <a class="al-sidebar-list-link" (click)="goToBenchmarkPage()"
       (mouseenter)="onHoverItem($event)"
       matTooltip="{{PAGE_NAMES.PLAN_BENCHMARK}}"
       matTooltipClass="mat-tooltip-custom"
       matTooltipPosition="right">
      <fa-icon [icon]="faUserChart" size="2x" fixedWidth></fa-icon>
      <!--<fa-layers size="2x" [fixedWidth]="true">
          <fa-icon [icon]="faChartLine" size="2x"></fa-icon>
          <fa-layers-counter content="B" class="benchmark-icon-badge"></fa-layers-counter>
      </fa-layers>-->

      <span translate>{{ PAGE_NAMES.PLAN_BENCHMARK }}</span>
    </a>
  </li>
<!--  <li class="al-sidebar-list-item"-->
<!--      [ngClass]="{ 'active-page': currentPageName === PAGE_NAMES.GOALS}"-->
<!--      *ripPerm="[perm.OWNER, perm.INVESTOR_PROFILE_EDIT]; ngIf: !inWealthFluent">-->
<!--    <a class="al-sidebar-list-link" (click)="goToGoalsPage()"-->
<!--       (mouseenter)="onHoverItem($event)"-->
<!--       matTooltip="{{PAGE_NAMES.GOALS}}"-->
<!--       matTooltipClass="mat-tooltip-custom"-->
<!--       matTooltipPosition="right">-->
<!--      <fa-icon [icon]="faAwardSimple" size="2x" fixedWidth style="margin-left: 20px; margin-right: 18px;"></fa-icon>-->
<!--      &lt;!&ndash;<fa-layers size="2x" [fixedWidth]="true">-->
<!--          <fa-icon [icon]="faChartLine" size="2x"></fa-icon>-->
<!--          <fa-layers-counter content="B" class="benchmark-icon-badge"></fa-layers-counter>-->
<!--      </fa-layers>&ndash;&gt;-->

<!--      <span translate>{{ PAGE_NAMES.GOALS }}</span>-->
<!--    </a>-->
<!--  </li>-->

</ul>
