import { Component, inject } from '@angular/core';
import { WealthFluentMessageService } from '../../theme/services';

@Component( {
  selector: 'app-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  styleUrls: [ './something-went-wrong.component.scss' ],
} )
export class SomethingWentWrongComponent {
  private wealthFluentMessageService: WealthFluentMessageService = inject( WealthFluentMessageService );
  working: boolean = false;

  constructor() {
  }

  askForNewToken(): void {
    this.working = true;
    this.wealthFluentMessageService.requestNewTokenFromWF();
    setTimeout( () => {
      this.working = false;
    }, 1200 );
  }

}
