/**
 * Created by joshua on 12/26/16.
 */
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import * as _ from 'lodash-es';
import { Auth } from '../auth.service';
import moment from 'moment';
import { environment } from '../../environments/environment';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { RipsawCurrencyPipe, RipsawDecimalPipe, RipsawPercentPipe } from '../theme/pipes';
import { AccountManager } from './accountManager';
import { GlobalState } from '../global.state';
import { Connection } from './dataInterfaces';
import { Account, ColorName, DeviationColor, DeviationColors, FormsUtil, Position } from '@ripsawllc/ripsaw-analyzer';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { EVENT_NAMES } from './enums';
import Diff = moment.unitOfTime.Diff;

export class Util {

  private static colors: DeviationColors = {
    red: { name: ColorName.red, hex: '#ff452b' },
    yellow: { name: ColorName.yellow, hex: '#fffc47' },
    green: { name: ColorName.green, hex: '#4cf01a' },
  };

  /*
   * Function for extracting data from an HttpResponse
   * @param res {HttpResponse} - HttpResponse that contains data to be extracted
   * @returns the body of the response or an empty object
   * */
  static extractData( res: HttpResponse<any> ) {
    return res || {};
  }

  /*
   * Function for handling http response errors
   * @param error {HttpErrorResponse} - response with an error
   * @returns a Promise with the error
   * */
  static handleError( error: HttpErrorResponse | any ) {
    // In a real world app, we might use a remote logging infrastructure
    let errMsg: string;

    errMsg = error.error?.error ? error.error.error : ( error.message ? error.message : error.toString() );

    const refCode = error.error ? error.error.referenceCode : null;
    // console.error( errMsg );
    return Promise.reject( { err: errMsg, refCode } );
  }

  /*
   * Function for creating a clone of a json object with no functions
   * @param obj {Object} - object to be cloned
   * @returns a clone of the object passed in
   * */
  static clone( obj ) {
    if ( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    }
  }

  /*
   * Function for getting a copy of an array that has no null or undefined elements in it
   * @param actual {Array} - array to be cleaned
   * @returns a new array with no null or undefined elements
   * */
  static cleanArray( actual ) {
    const newArray = [];
    for ( const i of actual ) {
      if ( i ) {
        newArray.push( i );
      }
    }
    return newArray;
  }

  /*
   * Function for checking how many decimals are in a number value and removing extra digits
   * @param value {String} - value to be checked
   * @param maxLength {number} - the max number of decimal places the value should have
   * @returns the value with extra digits cut off
   * */
  static checkDecimalLength( value: string, maxLength: number ) {
    const valArray = value.split( '.' );
    if ( valArray.length > 1 ) {
      if ( valArray[ 1 ].length > 2 ) {
        return valArray[ 0 ].concat( '.', valArray[ 1 ].substring( 0, maxLength ) );
      }
    }
    return value;
  }

  /*
   * Function that converts any NaN values to zeroes in the given object
   * @param obj {Object} - object that may have NaN values
   * */
  static convertNaNtoZero( obj: any ) {
    for ( const key of Object.keys( obj ) ) {
      const value = obj[ key ];
      if ( typeof value !== 'object' ) {
        if ( ( isNaN( value ) || value === null ) && typeof value !== 'string' ) {
          obj[ key ] = 0;
        }
      } else {
        Util.convertNaNtoZero( obj[ key ] );
      }
    }
  }

  /*
   * Function for merging security into position data
   * @param securities {Array} - securities to merge into the positions
   * @param positions {Array} - positions to have security data merged into
   * */
  static mergeSecuritiesAndPositions( securities: any, positions: any ) {
    positions.forEach( function ( p ) {
      const sec = _.find( securities, function ( s: any ) {
        return s.ticker === p.ticker;
      } );
      Object.assign( p, sec );
    } );
  }

  /*
   * Function for merging a single security into a single position
   * @param securities {Array} - list of securities to search
   * @param position {Object} - the position that needs security data
   * */
  static mergeSecurityAndPosition( securities: any, position: any ) {
    const sec = _.find( securities, function ( s: any ) {
      return s.ticker === position.ticker;
    } );

    Object.assign( position, sec );
  }

  /*
   * Function for base64 encrypting a string
   * */
  static btoa( str: string ): string {
    return window.btoa( str );
  }

  /*
   * Function for formatting an account's description for display
   * @param account {Object} - the account whose display description needs to be formatted
   * @returns a formatted account description
   * */
  static formatAccountDescription( account: any, noInstName?: boolean, truncate?: number ) {
    const instName = account.institution_name ? `${ account.institution_name } ` : '';
    const desc = account.description ? `${ account.description } ` : '';
    let formatted = noInstName ? `${ desc } ` : `${ instName }${ desc } `;
    if ( account.name && !desc.includes( account.name.trim() ) ) {
      formatted = `${ formatted } ${ account.name }`;
    }
    if ( truncate && truncate < formatted.length ) {
      formatted = `${ formatted.substring( 0, truncate ) }...`;
    }
    return formatted;
  }

  /*
   * Function for retrieving the user object fro the Auth service
   * @param auth {Auth} - the Auth service
   * @returns the user object stored in session storage by the Auth service
   * */
  static getLoggedInUser( auth: Auth ): any {
    return auth.getTokenItem( 'user' );
  }

  /*
   * Function for converting a date to an age
   * @param birthday {Date} - a date to be converted
   * @returns a decimal value age number
   * */
  static convertBirthdateToAge( birthday: any ) {
    const mBirthday = moment( birthday );
    return Math.abs( mBirthday.diff( moment(), 'years', true ) );
  }

  /*
   static createMomentFromDate(dateVal: Date) {
   return moment(dateVal.toISOString().substring(0, 10), 'YYYY-MM-DD', true); // pass date with yyyy-MM-dd format to the constructor and require strict mode
   }
   */

  /*
   * Function for determining all the classes a data grid cell should have based on row and column data
   * @param row {Object} - row data
   * @param column {Object} - column object
   * @returns a set of classes and their boolean value
   * */
  static getCellClass( { row, column/*, value*/ } ) {
    return {
      'net-worth-current': column.prop === 'net_worth' && row.allocDataType === 'Current',
      'new-security': row && row.newSecurity,
      'revised-right-side': column.prop === 'revised_allocation',
      'values': column.prop === 'revised_value' || column.prop === 'value',
      'up': column.prop === 'gain_loss' && row.gain_loss > 0,
      'down': column.prop === 'gain_loss' && row.gain_loss < 0,
      'revised-cell': [
        'revised_quantity',
        'revised_difference',
        'revised_value',
        'revised_allocation',
      ].includes( column.prop ),
      'group-left-side': [
        'real_assets',
        'cash',
        'annualized_yield',
        'cost_basis',
        'large_cap',
        'value_stocks',
        'aaa',
        'us',
        'bonds_us',
        'less_than_one_year',
        'sector_basic_materials',
        'bond_primary_sector_agency_mbs',
        'maturity_date',
        'security_style',
        'morningstar_category',
        'one_month',
        // 'expense_ratio',
        // 'expenses',
      ].includes( column.prop ),
      'highlight-column': column.highlight ||
        ( row.highlightNewValue && [ 'revised_value', 'optimizerConstraints' ].includes( column.prop ) ),
      'ticker-name': column.prop === 'ticker_name',
      'highlight-change': [ 'revised_quantity', 'revised_difference', 'revised_value', 'revised_allocation', 'buySell' ].includes( column.prop ) && row.revised_quantity !== 0,
      /* 'group-right-side': [
       'stocks',
       'small_cap',
       'growth_stocks',
       'not_rated',
       'non_us',
       'bonds_non_us',
       'emerging_markets',
       'bonds_region_emerging_markets',
       'over_thirty_years',
       'sector_utilities',
       'bond_primary_sector_us_municipal_tax_advantaged',
       'maturity_date',
       ].includes( column.prop ),*/
    };
  }

  /*
   * Function for getting common snack bar options
   * @param [stay] {boolean} - if true, the options returned includes a duration of 0 which will keep the snack bar open
   * indefinitely, otherwise, the snack bar dismisses after 5 seconds
   * */
  static getSnackBarOptions( stay?: boolean ): any {
    const duration = stay ? 0 : 5000;
    return { duration, verticalPosition: 'top', panelClass: 'snack-bar' };
  }

  /*
   * Function for retrieving the wealthfluent support email address based on which environment the instance is on
   * */
  static getSupportAddress(): string {
    return environment.env === 'prod' ? 'support@wealthfluent.com' : `${ environment.env }_support@wealthfluent.com`;
  }

  /*
   * Function for getting a common string telling the user to contact support
   * */
  static getContactSupportString(): string {
    return `If the problem persists, please contact support at ${ this.getSupportAddress() }`;
  }

  /*
   * Function for getting a common string telling the user to contact support and provide a reference code
   * */
  static getRefCodeSupportString( code: string ): string {
    if ( code ) {
      return `${ Util.getContactSupportString() } and provide this Error Reference Code: ${ code }`;
    } else {
      return Util.getContactSupportString();
    }
  }

  /* Functions for formatting inputs in forms */
  static updateInputPercentFormat( formControlName: string, form: UntypedFormGroup, longForm?: boolean ) {
    const inputValue = form.controls[ formControlName ].value;
    Util.setFormattedValue( formControlName, 'Percent', inputValue, form, longForm );
  }

  static updateInputCurrencyFormat( formControlName: string, form: UntypedFormGroup ) {
    const inputValue = form.controls[ formControlName ].value;
    Util.setFormattedValue( formControlName, 'Currency', inputValue, form );
  }

  static updateInputDecimalFormat( formControlName: string, form: UntypedFormGroup ) {
    const inputValue = form.controls[ formControlName ].value;
    Util.setFormattedValue( formControlName, 'Decimal', inputValue, form );
  }

  static setFormattedValue( formControlName: string, formatType: string, inputValue, form: UntypedFormGroup, longForm?: boolean ) {
    inputValue = FormsUtil.getSanitizedFloatValue( inputValue, false );
    if ( formatType === 'Decimal' ) {
      form.controls[ formControlName ].setValue( new RipsawDecimalPipe().transform( inputValue, '2-2' ) );
    } else if ( formatType === 'Percent' ) {
      form.controls[ formControlName ].setValue( new RipsawPercentPipe().transform( inputValue / 100, longForm ? '2-4' : '2-2' ) );
    } else if ( formatType === 'Currency' ) {
      form.controls[ formControlName ].setValue( new RipsawCurrencyPipe().transform( inputValue ) );
    }
  }

  // START REMOVAL
  static accountIsLoan( a: any ) {
    return a.account_category.toLowerCase() === 'loan';
  }

  static accountIsCreditCard( a: any ) {
    return a.account_type.toLowerCase() === 'credit card';
  }

  static accountIsInvestment( a: any ) {
    return a.account_category.toLowerCase() === 'investment';
  }

  static accountIsRealAsset( a: any ) {
    return [ 'real estate', 'vehicle', 'valuables', 'valuable(s)' ].includes( a.account_type.toLowerCase() );
  }

  static accountIsValuables( a: any ) {
    return [ 'valuables', 'valuable(s)' ].includes( a.account_type.toLowerCase() );
  }

  static accountIsBanking( a: any ) {
    return a.account_category.toLowerCase() === 'banking' && ![ 'credit card', 'cash' ].includes( a.account_type.toLowerCase() );
  }

  static accountIsBrokerage( a: any ) {
    return a.account_category.toLowerCase() === 'investment' &&
      ![ 'bond', 'private lending', 'restricted stock', 'stock', 'stock option', 'stock plan' ].includes( a.account_type.toLowerCase() );
  }

  static accountIsAnnuity( a: any ) {
    return a.account_type.toLowerCase() === 'annuity' && a.positions.length <= 1;
  }

  static accountIsPensionOrSS( a: any ) {
    return Util.accountIsAnnuity( a ) && a.annuity_type !== 'annuity';
  }

  static accountIsStockOption( a: any ) {
    return a.account_type?.toLowerCase() === 'stock option';
  }

  static positionIsStockOption( p: Position ) {
    // these two checks should include options coming from connected accounts or options added as manual
    return p?.security_type?.toLowerCase().includes( 'option' ) || p?.ticker?.toLowerCase().includes( 'option' );
  }

  static accountIsStock( a: any ) {
    return a.account_type?.toLowerCase() === 'stock';
  }

  static accountIsRealAssetWizardGenerated( a: any ): boolean {
    return ( a.account_type?.toLowerCase() === 'real estate' || a.account_type?.toLowerCase() === 'vehicle' )
      && a.added_in_revision
      && ( a.downPayment || a.corresponding_liability_id );
  }

  static accountIsPurchasedAnnuityPurchase( a: any ): boolean {
    return a.account_type?.toLowerCase() === 'annuity' && a.added_in_revision && a.purchasePrice;
  }

  static accountIsNewInvestment( a: any ) {
    return Util.accountIsInvestment( a ) && a.added_in_revision;
  }

  static accountIsManualBond( a: any ) {
    return Util.accountIsInvestment( a ) &&
      a.positions &&
      a.positions.length > 0 &&
      a.positions[ 0 ].ticker_name &&
      a.positions[ 0 ].ticker_name.toLowerCase() === 'manual bond';
  }

  static accountIsPrivateLending( a: any ) {
    return Util.accountIsInvestment( a ) &&
      a.positions &&
      a.positions.length > 0 &&
      a.positions[ 0 ].ticker_name &&
      a.positions[ 0 ].ticker_name.toLowerCase() === 'manual loan';
  }

  static accountIsCrypto( a: any ) {
    return a.account_type.toLowerCase() === 'crypto';
  }

  static accountCanHaveFees( account: Account ) {
    // manual investment accounts, all bank accounts, connected investment category accounts, credit cards
    return Util.accountIsBanking( account ) ||
      ( Util.accountIsInvestment( account ) && !account.isManual ) ||
      Util.accountIsBrokerage( account ) ||
      Util.accountIsCreditCard( account );
  }

  // END REMOVE

  /**
   * Function finds the ascendant of the given HTMLElement with the given id
   * @param element - HTMLElement to start with
   * @param id - id of the ascendant element to search for
   */
  static findAscendantElementById( element: HTMLElement, id: string ) {
    if ( !element.parentElement ) {
      return null;
    }
    if ( element.parentElement.id === id ) {
      return element.parentElement;
    }
    return Util.findAscendantElementById( element.parentElement, id );
  }

  /**
   * Function finds the ascendant of the given HTMLElement with the given class name. Will return the first ascendant
   * with the matching class
   * @param element - HTMLElement to start with
   * @param className - class name of the ascendant element to search for
   */
  static findAscendantElementByClass( element: HTMLElement, className: string ) {
    if ( !element.parentElement ) {
      return null;
    }
    if ( element.parentElement.className.includes( className ) ) {
      return element.parentElement;
    }
    return Util.findAscendantElementByClass( element.parentElement, className );
  }

  /**
   *
   * @param element - container to scroll
   * @param to - offset to scroll to
   * @param duration - duration the scroll animation should last
   * @param direction - direction of the scroll
   */
  static scrollTo( element, to, duration, direction? ) {
    let currentTime = 0, directionProp = 'scrollTop';

    if ( direction && direction === 'horizontal' ) {
      directionProp = 'scrollLeft';
    }

    const start = element[ directionProp ],
      change = to - start,
      increment = 50; // make this smaller to make the scroll seem smoother or larger to be more performant


    const animateScroll = function () {
      currentTime += increment;
      element[ directionProp ] = duration === 0 ? change : Util.easeInOutQuad( currentTime, start, change, duration );
      if ( currentTime < duration ) {
        setTimeout( animateScroll, increment );
      }
    };
    animateScroll();
  }

  /*
   t = current time
   b = start value
   c = change in value
   d = duration
   */
  private static easeInOutQuad( t, b, c, d ) {
    t /= d / 2;
    if ( t < 1 ) {
      return c / 2 * t * t + b;
    }
    t--;
    return -c / 2 * ( t * ( t - 2 ) - 1 ) + b;
  }

  static scrollToFirstColumn( component: any ) {
    // console.log( `gonna scroll to beginning` );
    setTimeout( () => {
      const tableBody = component._elRef.nativeElement.getElementsByClassName( 'datatable-body' );
      if ( tableBody && tableBody.length > 0 ) {
        const duration = component.account ? 50 : 500;
        Util.scrollTo( tableBody[ 0 ], 0, duration, 'horizontal' );
        Util.doChanges( component );
      }
    }, 500 );
  }

  static scrollToColumn( group: any, component: any, duration?: number ) {
    if ( !duration ) {
      duration = 500;
    }
    // console.log( `gonna scroll to ${ group.label }` );
    const cols = component.columns.filter( ( c: any ) => {
      return group.columns.includes( c.prop );
    } );
    for ( const c of cols ) {
      c.highlight = true;
    }
    Util.doChanges( component );
    setTimeout( () => {
      const tableBody = component._elRef.nativeElement.getElementsByClassName( 'datatable-body' );
      if ( tableBody && tableBody.length > 0 ) {
        const highlightedElements = component._elRef.nativeElement.getElementsByClassName( 'highlight-column' );
        const firstElem = highlightedElements[ 0 ];
        if ( firstElem ) {
          const dur = component.account ? 50 : duration;
          Util.scrollTo( tableBody[ 0 ], firstElem.offsetLeft, dur, 'horizontal' );
          Util.doChanges( component );
        }
        setTimeout( () => {
          for ( const c of cols ) {
            c.highlight = undefined;
          }
          Util.doChanges( component );
        }, 9000 );
      }
    }, 500 );

  }

  static doChanges( component: any ) {
    component._cd.detach();
    component.rows = [ ...component.rows ];
    component.columns = [ ...component.columns ];
    component._cd.markForCheck();
    component._cd.reattach();
  }

  static truncateNumber( value: number, numberOfDecimals?: number ) {
    if ( numberOfDecimals === undefined || numberOfDecimals === null ) {
      numberOfDecimals = 2;
    }
    const parts = value.toString().split( '.' );
    if ( parts.length > 1 ) {
      const truncatedDecimals = parts[ 1 ].substring( 0, numberOfDecimals );
      return parseFloat( `${ parts[ 0 ] }.${ truncatedDecimals }` );
    } else {
      return value;
    }
    // const matcher = new RegExp( '/^-?\d+(?:\.\d{0,' + numberOfDecimals + '})?/' );
    // return value.toString().match( matcher )[0];
  }

  /*
   * firstColor and secondColor are objects with a red, green, and blue property that are all numbers
   * */

  /*static calcColorGradient( startColor: any, endColor: any, percentFade: number ) {
   const diffRed = endColor.red - startColor.red;
   const diffGreen = endColor.green - startColor.green;
   const diffBlue = endColor.blue - startColor.blue;

   const newRed = (diffRed * percentFade) + startColor.red;
   const newGreen = (diffGreen * percentFade) + startColor.green;
   const newBlue = (diffBlue * percentFade) + startColor.blue;

   return { red: newRed, green: newGreen, blue: newBlue };
   }*/

  static generateRandomString( length: number ) {
    length = length || 10;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
    }
    return result;
  }

  static checkMaturity( p: any ) {
    let yearsLeft;
    const today = moment();
    if ( p.maturity_date ) {
      const md = moment( p.maturity_date );
      yearsLeft = Math.abs( md.diff( today, 'years' ) );
    } else if ( p.loan_term && p.loan_origination_date ) {
      const originationDate = moment( p.loan_origination_date );
      yearsLeft = p.loan_term - Math.abs( today.diff( originationDate, 'years' ) );
    }
    if ( yearsLeft ) {
      if ( p.amortization_type === 'interest-only' ) {
        Object.assign( p, {
          less_than_one_year: 0,
          one_to_three_years: 0,
          three_to_five_years: 0,
          five_to_seven_years: 0,
          seven_to_ten_years: 0,
          ten_to_fifteen_years: 0,
          fifteen_to_twenty_years: 0,
          twenty_to_thirty_years: 0,
          over_thirty_years: 0,
        } );
        const matBucket = Util.getInterestOnlyMaturityBucket( yearsLeft );
        p[ matBucket ] = 1;
      } else {
        // fully-amortizing
        Util.setMaturityStructure( yearsLeft, p );
      }
    }
  }

  static getInterestOnlyMaturityBucket( maturityInYears: number ) {
    if ( maturityInYears >= 30 ) {
      return 'over_thirty_years';
    } else if ( maturityInYears >= 20 ) {
      return 'twenty_to_thirty_years';
    } else if ( maturityInYears >= 15 ) {
      return 'fifteen_to_twenty_years';
    } else if ( maturityInYears >= 10 ) {
      return 'ten_to_fifteen_years';
    } else if ( maturityInYears >= 7 ) {
      return 'seven_to_ten_years';
    } else if ( maturityInYears >= 5 ) {
      return 'five_to_seven_years';
    } else if ( maturityInYears >= 3 ) {
      return 'three_to_five_years';
    } else if ( maturityInYears >= 1 ) {
      return 'one_to_three_years';
    } else {
      return 'less_than_one_year';
    }
  }

  static setMaturityStructure( maturityInYears: number, position: any, returnObj?: boolean ) {
    let counter = 1;
    const maturityStructure = {
      less_than_one_year: 0,
      one_to_three_years: 0,
      three_to_five_years: 0,
      five_to_seven_years: 0,
      seven_to_ten_years: 0,
      ten_to_fifteen_years: 0,
      fifteen_to_twenty_years: 0,
      twenty_to_thirty_years: 0,
      over_thirty_years: 0,
    };
    if ( !returnObj ) {
      Object.assign( position, maturityStructure );
    }
    if ( maturityInYears < 1 ) {
      maturityStructure.less_than_one_year = 1;
    } else {
      counter++;
    }
    if ( maturityInYears >= 1 && maturityInYears < 3 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years = 1 / counter;
    } else {
      counter++;
    }
    if ( maturityInYears >= 3 && maturityInYears < 5 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years =
          maturityStructure.three_to_five_years = 1 / counter;
    } else {
      counter++;
    }
    if ( maturityInYears >= 5 && maturityInYears < 7 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years =
          maturityStructure.three_to_five_years =
            maturityStructure.five_to_seven_years = 1 / counter;
    } else {
      counter++;
    }
    if ( maturityInYears >= 7 && maturityInYears < 10 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years =
          maturityStructure.three_to_five_years =
            maturityStructure.five_to_seven_years =
              maturityStructure.seven_to_ten_years = 1 / counter;
    } else {
      counter++;
    }
    if ( maturityInYears >= 10 && maturityInYears < 15 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years =
          maturityStructure.three_to_five_years =
            maturityStructure.five_to_seven_years =
              maturityStructure.seven_to_ten_years =
                maturityStructure.ten_to_fifteen_years = 1 / counter;
    } else {
      counter++;
    }
    if ( maturityInYears >= 15 && maturityInYears < 20 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years =
          maturityStructure.three_to_five_years =
            maturityStructure.five_to_seven_years =
              maturityStructure.seven_to_ten_years =
                maturityStructure.ten_to_fifteen_years =
                  maturityStructure.fifteen_to_twenty_years = 1 / counter;
    } else {
      counter++;
    }
    if ( maturityInYears >= 20 && maturityInYears < 30 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years =
          maturityStructure.three_to_five_years =
            maturityStructure.five_to_seven_years =
              maturityStructure.seven_to_ten_years =
                maturityStructure.ten_to_fifteen_years =
                  maturityStructure.fifteen_to_twenty_years =
                    maturityStructure.twenty_to_thirty_years = 1 / counter;
    } else {
      counter++;
    }
    if ( maturityInYears >= 30 ) {
      maturityStructure.less_than_one_year =
        maturityStructure.one_to_three_years =
          maturityStructure.three_to_five_years =
            maturityStructure.five_to_seven_years =
              maturityStructure.seven_to_ten_years =
                maturityStructure.ten_to_fifteen_years =
                  maturityStructure.fifteen_to_twenty_years =
                    maturityStructure.twenty_to_thirty_years =
                      maturityStructure.over_thirty_years = 1 / counter;
    }
    if ( returnObj ) {
      return maturityStructure;
    } else {
      Object.assign( position, maturityStructure );
    }
  }

  static removeEmptyProperties( obj ) {
    Object.keys( obj ).forEach( ( key ) => ( obj[ key ] === null ) && delete obj[ key ] );
  }

  static getAccountCategoryOrderIndex( cat: string, type: string, accountManager: AccountManager ) {
    if ( cat === 'Other' ) {
      cat = type;
    }

    if ( isNaN( accountManager.categoryOrder[ cat ] ) ) {
      cat = 'Other';
    }
    return accountManager.categoryOrder[ cat ];
  }

  static sortPositions( sort, rows, direction ) {
    if ( sort === 'one_day_change' ) {
      const positionsWithOneDay = _.filter( rows, ( p: any ) => {
        return p.percentChange !== null && p.percentChange !== undefined && p.percentChange !== 0;
      } );
      const positionsWithoutOneDay =
        _.filter( rows, ( p: any ) => {
          return p.percentChange === null || p.percentChange === undefined || p.percentChange === 0;
        } );
      Util.positionSortFunction( sort, positionsWithOneDay, direction );
      return positionsWithOneDay.concat( positionsWithoutOneDay );
    } else {
      Util.positionSortFunction( sort, rows, direction );
    }
  }

  static positionSortFunction( sort, rows, direction ) {
    // needed to do a custom sorting function because we wanted the settlement funds to always be the first row

    rows.sort( ( a, b ) => {
      if ( sort === 'one_day_change' ) {
        return direction === 'asc' ? Util.compareOneDayNumber( a.percentChange, b.percentChange ) :
          Util.compareOneDayNumber( b.percentChange, a.percentChange );
      } else {
        if ( !a || !b ) {
          return 0;
        }
        // don't need this once we move the settlement fund to the account and out of positions/rows
        if ( direction === 'asc' ) {
          // check to see if we're sorting a string or not because numbers don't have localeCompare function
          if ( typeof a[ sort ] === 'string' ) {
            return a[ sort ].localeCompare( b[ sort ] );
          } else {
            return Util.compareNumber( a[ sort ], b[ sort ] );
          }
        } else {
          // check to see if we're sorting a string or not because numbers don't have localeCompare function
          if ( typeof b[ sort ] === 'string' ) {
            return b[ sort ].localeCompare( a[ sort ] );
          } else {
            return Util.compareNumber( b[ sort ], a[ sort ] );
          }
        }
      }
    } );
  }

  static compareOneDayNumber( a, b ) {
    // if a has no one day change (no value or 0), but b does, b is "greater"
    if ( ( b !== null && b !== undefined && b !== 0 ) && ( a === null || a === undefined || a === 0 ) ) {
      return 1;
    }
    // if b has no one day change(no value or 0), but a does, a is "greater"
    if ( ( b === null || b === undefined || b === 0 ) && ( a !== null && a !== undefined && a !== 0 ) ) {
      return -1;
    }
    // if a and b both have no one day change (no value or 0), they are "equal"
    if ( ( a === null || a === undefined || a === 0 ) && ( b === null || b === undefined || b === 0 ) ) {
      return 0;
    }
    // if both a and be have a value, compare the values
    if ( b > a ) {
      return 1;
    } else if ( b < a ) {
      return -1;
    } else {
      return 0;
    }
  }

  static compareNumber( a, b ) {
    // if a has no value, but b does, b is "greater"
    if ( ( a === null || a === undefined ) && ( b !== null && b !== undefined ) ) {
      return -1;
    }
    // if b has no value, but a does, a is "greater"
    if ( ( b === null || b === undefined ) && ( a !== null && a !== undefined ) ) {
      return 1;
    }
    // if a and b both have no value, they are "equal"
    if ( ( a === null || a === undefined ) && ( b === null || b === undefined ) ) {
      return 0;
    }
    // if both a and be have a value, compare the values
    if ( b > a ) {
      return -1;
    } else if ( b < a ) {
      return 1;
    } else {
      return 0;
    }
  }

  static getDeviationTooltip( color: string ) {
    switch ( color ) {
      case 'red':
        return 'This portfolio dimension exceeds the deviation threshold relative to your benchmark';
      case 'yellow':
        return 'This portfolio dimension exceeds 50% of the deviation threshold relative to your benchmark';
      case 'green':
        return 'This portfolio dimension is within 50% of the deviation threshold relative to your benchmark';
    }
  }

  static getDeviationColor( deviation: number, threshold: number ): DeviationColor {
    if ( Math.abs( deviation ) > threshold ) {
      return Util.getDeviationColorObjectByName( 'red' ); // danger
    } else if ( Math.abs( deviation ) > threshold / 2 ) {
      return Util.getDeviationColorObjectByName( 'yellow' ); // warning
    } else {
      return Util.getDeviationColorObjectByName( 'green' ); // success
    }
  }

  static getDeviationColorObjectByName( name: string ): DeviationColor {
    return Util.colors[ name ];
  }

  static getRandomElementFromArray( array: any[] ) {
    return array[ Math.floor( Math.random() * array.length ) ];
  }

  static toTitleCase( str ) {
    return str.replace(
      /\w\S*/g,
      ( txt ) => {
        return txt.charAt( 0 ).toUpperCase() + txt.substr( 1 ).toLowerCase();
      },
    );
  }

  /**
   * This function returns a boolean of whether the given account's status is FAILED && it's last good sync is less
   * than 24 hours ago which means it might just be being blocked by the institution
   * @param account - account whose status and last_good_sync to check
   */
  static accountSyncedRecentlyButFailed( account: Account ) {
    return account.status === 'FAILED' && ( Math.abs( moment().diff( moment( account.last_good_sync ), 'days' ) ) < 1 );
  }

  /**
   * This function returns a boolean of whether the given connection's status is FAILED && it's last good sync is less
   * than 24 hours ago which means it might just be being blocked by the institution
   * @param connection - connection whose status and last_good_sync to check
   */
  static connectionSyncedRecentlyButFailed( connection: Connection ) {
    return connection.status === 'FAILED' && ( Math.abs( moment().diff( moment( connection.last_good_sync ), 'days' ) ) < 1 );
  }

  static isWebkitHandlerAvailable() {
    const webkit = window[ 'webkit' ];
    return !!( webkit && webkit.messageHandlers && webkit.messageHandlers.jsHandler );
  }

  static sendWebkitHandlerMessage( type: string, data?: any ) {
    const webkit = window[ 'webkit' ];
    webkit.messageHandlers.jsHandler.postMessage( { type, data: data || {} } );
  }

  static openExternalUrl( url: string ) {
    if ( Util.isWebkitHandlerAvailable() ) {
      Util.sendWebkitHandlerMessage( 'externalUrl', { url } );
    } else {
      window.open( url, '_blank' );
    }
  }

  static setRedirectUrl( hash: string, state: GlobalState ) {

    if ( !state.redirectUrl && Util.pageNeedsToBeRedirectedTo( hash ) ) {
      state.redirectUrl = hash.replace( '#', '' );
      if ( state.redirectUrl.includes( 'startRev' ) ) {
        // to remove the startRev parameters so we don't see the optimizer run endlessly
        state.redirectUrl = state.redirectUrl.split( '?' )[ 0 ];
      }
    }
  }

  static pageNeedsToBeRedirectedTo( hash: string ) {
    return !hash.includes( 'login' ) &&
      !hash.includes( 'register' ) &&
      !hash.includes( 'forgot' ) &&
      !hash.includes( 'reset' );
  }

  /**
   *
   * @param account_id - account_id of the account to be opened
   * @param ticker - ticker of the position that needs to be overridden
   * @param state - GlobalState
   * @param router - Angular Router instance
   * @param dialogRef - mat dialog ref that will be closed so the override modal can be opened
   */
  static goToAccount( account_id: any, ticker: string, state: GlobalState, router: Router, dialogRef?: MatDialogRef<any> ) {
    if ( !router.url.includes( 'accounts' ) ) {
      router.navigate( [ 'pages/accounts' ], { queryParams: { location: account_id } } ).then( () => {
        return;
      } ).catch( ( err ) => {
        console.error( err );
      } );
    }
    setTimeout( () => {
      dialogRef?.close();
      state.notifyDataChanged( EVENT_NAMES.EXPAND_ACCOUNT, { account_id, ticker } );
    }, 500 );

  }

  /**
   *
   * @param date - date to check
   * @param fromDate - beginning date
   * @param toDate - ending date
   */
  static isInDateRange( date: moment.Moment, fromDate: moment.Moment, toDate: moment.Moment ) {
    return date.isAfter( fromDate ) && date.isBefore( toDate );
  }

  static unCamelCase( str: string ) {
    const result = str.replace( /([A-Z])/g, ' $1' );
    return result.charAt( 0 ).toUpperCase() + result.slice( 1 );
  }

  static diffMomentByUTC( a: moment.Moment, b: moment.Moment, unit: Diff, precision?: boolean ) {
    return a.utc().diff( b.utc(), unit, precision );
  }

  static getFormValueAsNumber( form: FormGroup, controlName: string ): number {
    return FormsUtil.getSanitizedFloatValue( form.controls[ controlName ].value );
  }

}
