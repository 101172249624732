<ul id="al-sidebar-list" class="al-sidebar-list al-scrollable-list" ngaBaSlimScroll
    (mouseleave)="hoverElemTop=outOfArea">

  <rip-sidebar-workspace
   [sidebarCollapsed]="sidebarCollapsed"
   [style.margin-bottom.rem]="1"
   *ngIf="!inWealthFluent"
  ></rip-sidebar-workspace>

  <rip-pages-menu
    (itemHover)="hoverItem($event)"
    [sidebarCollapsed]="sidebarCollapsed"
    *ngIf="!subscriptionInactive">

  </rip-pages-menu>


  <rip-preferences
    [sidebarCollapsed]="sidebarCollapsed"
    (itemHover)="hoverItem($event)"
    *ngIf="!subscriptionInactive">

  </rip-preferences>

</ul>

<div>
  <rip-user-menu (itemHover)="hoverItem($event)" [sidebarCollapsed]="sidebarCollapsed" *ngIf="!inWealthFluent"></rip-user-menu>
<!--  <rip-help-menu [sidebarCollapsed]="sidebarCollapsed" *ngIf="!inWealthFluent"></rip-help-menu>-->
</div>

<div class="sidebar-hover-elem" [ngStyle]="{top: hoverElemTop + 'px', height: hoverElemHeight + 'px'}"
     [ngClass]="{'show-hover-elem': showHoverElem }">
</div>
