import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BaMenuService } from '../../../../services';
import { GlobalState } from '../../../../../global.state';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component( {
  selector: 'nga-ba-menu',
  templateUrl: './baMenu.html',
  styleUrls: ['./baMenu.scss'],
} )
export class BaMenuComponent implements OnInit, OnDestroy {

  private readonly onDestroy: Subject<void> = new Subject<void>();

  @Input() sidebarCollapsed: boolean = false;
  @Input() menuHeight: number;
  @Input() subscriptionInactive: boolean;

  @Output() expandMenu: EventEmitter<any> = new EventEmitter<any>();

  menuItems: any[];
  protected _menuItemsSub: Subscription;
  showHoverElem: boolean;
  hoverElemHeight: number;
  hoverElemTop: number;
  protected _onRouteChange: Subscription;
  outOfArea: number = -200;

  inWealthFluent: boolean = false;

  constructor( private _router: Router,
               private _service: BaMenuService,
               private _state: GlobalState ) {

  }

  updateMenu( newMenuItems: any[] ): void {
    this.menuItems = newMenuItems;
    this.selectMenuAndNotify();
  }

  selectMenuAndNotify(): void {
    if ( this.menuItems ) {
      this.menuItems = this._service.selectMenuItem( this.menuItems );
      this._state.notifyDataChanged( 'menu.activeLink', this._service.getCurrentItem() );
    }
  }

  ngOnInit(): void {
    this.inWealthFluent = this._state.globalVars.inWealthFluent;
    this._onRouteChange = this._router.events
      .pipe(takeUntil(this.onDestroy))
      .subscribe( ( event ) => {

      if ( event instanceof NavigationEnd ) {
        if ( this.menuItems ) {
          this.selectMenuAndNotify();
        } else {
          // on page load we have to wait as event is fired before menu elements are prepared
          setTimeout( () => this.selectMenuAndNotify() );
        }
      }
    } );

    this._menuItemsSub = this._service.menuItems
      .pipe(takeUntil(this.onDestroy))
      .subscribe( this.updateMenu.bind( this ) );
  }

  ngOnDestroy(): void {
    this._onRouteChange.unsubscribe();
    this._menuItemsSub.unsubscribe();
    this.onDestroy.next();
  }

  hoverItem( event: any ): void {
    this.showHoverElem = true;
    this.hoverElemHeight = event.currentTarget.clientHeight;
    this.hoverElemTop = event.currentTarget.getBoundingClientRect().top;
  }

}
