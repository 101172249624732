import { ChangeDetectorRef, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BaThemeConfig } from './theme.config';

import { BaThemeConfigProvider } from './theme.configProvider';

import {
  BaBackTopComponent,
  BaContentTopComponent,
  BaMenuComponent,
  BaMenuItemComponent,
  BaSidebarComponent,
  SidebarWorkspaceComponent,
  WorkspaceMenuComponent,
} from './components';

import { ColumnSelectorComponent } from '../pages/modals/columnSelector';

import {
  AccountVisibilityBySettingPermissionPipe,
  ActivePricesPipe,
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
  RipDateInYearAndMonthPipe,
  RipsawAbbreviatedNumberPipe,
  RipsawCalendarYearPipePipe,
  RipsawCreditDebitPipe,
  RipsawCurrencyPipe,
  RipsawDatePipe,
  RipsawDecimalPipe,
  RipsawGoalOwnerNamesPipe,
  RipsawLongDatePipe,
  RipsawLongPercentPipe,
  RipsawPercentPipe,
  RipsawRemoveUnderscoresPipe,
  RipsawTruncatePipe,
  RipsawTwoPercentPipe,
  RipsawUnHyphenatePipe,
  SafePipe,
  StripHtmlPipe,
} from './pipes';

import {
  BaScrollPositionDirective,
  BaSlimScrollDirective,
  BaThemeRunDirective,
  FocusDirective,
  RipFeatureToggleDirective,
  RipPrintDirective,
} from './directives';

import { BaImageLoaderService, BaMenuService, BaThemePreloader, RipThemeLoadingSpinnerService, TransactionsService } from './services';

import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { DateValidator, EmailValidator, EqualPasswordsValidator } from './validators';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileService } from '../pages/profile/profile.service';
import { ManualAccountManagerComponent } from '../pages/modals/manualAccountManager';
import { ManualAccountManagerState } from '../utils/manualAccountManager.state';
import {
  AddManualAccountWidgetComponent,
  AnnuityFormComponent,
  BankFormComponent,
  BondFormComponent,
  CashFormComponent,
  CryptoFormComponent,
  InvestmentFormComponent,
  LoanFormComponent,
  RealAssetFormComponent,
  StockFormComponent,
  StockOptionFormComponent,
  TermLifeInsuranceFormComponent,
} from '../pages/modals/manualAccountManager/components';
import { ManualAccountFormComponent } from '../pages/modals/manualAccountManager/manualAccountFormInterface';
import { ColumnSelectorMenuComponent } from '../reusableWidgets/allocationWidget/components/columnSelectorMenu';
import { SecurityFinderComponent } from '../pages/modals/newInvestmentSelectorModal/components/securityFinder';
import { NewInvestmentSelectorModalComponent } from '../pages/modals/newInvestmentSelectorModal/newInvestmentSelectorModal.component';
import { NewInvestmentSelectorComponent } from '../pages/modals/newInvestmentSelectorModal/components/newInvestmentSelector/newInvestmentSelector.component';
import { ScreenerComponent } from '../pages/modals/newInvestmentSelectorModal/components/screener/screener.component';
import { MatchingFundDataSourceComponent } from '../pages/modals/newInvestmentSelectorModal/components/screener/components/matching-fund-data-source/matching-fund-data-source.component';
import { PreferencesComponent } from './components/baSidebar/components/baMenu/components/preferences/preferences.component';
import { DisclaimersComponent } from '../pages/modals/disclaimers/disclaimers.component';
import { WidgetMenuComponent } from '../reusableWidgets/allocationWidget/components/widgetMenu';
import { UserMenuComponent } from './components/baSidebar/components/baMenu/components/userMenu';
import { ManualSecurityEditorComponent } from '../pages/modals/manualSecurityEditor';
import { OverrideHelpers } from '../utils/overrideHelpers';
import { Util } from '../utils/util.service';
import { MobileUtil } from '../utils/mobileUtil.service';
import { MarketDataService, NotificationsService, PromoCodeService } from '../globalData';
import { FastLinkComponent } from '../pages/modals/fastLink/fastLink.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PagesMenuComponent } from './components/baSidebar/components/baMenu/components/pagesMenu';
import { GoToGroupMenuComponent } from '../reusableWidgets/allocationWidget/components/goToColumnGroupMenu';
import { BenchmarkGaugesComponent } from '../reusableWidgets/allocationWidget/components/benchmarkGauges/benchmarkGauges.component';
import { MatRadioModule } from '@angular/material/radio';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MobileDashboardMenuComponent } from '../reusableWidgets/allocationWidget/components/mobileDashboardMenu';
import { AllocationChartsComponent } from '../reusableWidgets/allocationWidget/components/allocationCharts';
import {
  NotificationComponent,
  NotificationsComponent,
} from './components/baSidebar/components/baMenu/components/userMenu/components/notifications';
import { AddExposureWizardComponent } from '../pages/modals/addExposureWizard';
import { BenchmarkExposureChoicesComponent } from '../pages/modals/addExposureWizard/components/benchmarkExposureChoices';
import { InvestmentSelectorUtil } from '../pages/modals/newInvestmentSelectorModal/components/utils/investmentSelectorUtil';
import { ExposureChoicesComponent } from '../pages/modals/addExposureWizard/components/exposureChoices';
import { ScreenerNavComponent } from '../pages/modals/newInvestmentSelectorModal/components/screener/components/screenerNav';
import { GroupDiffDisplayComponent } from '../pages/modals/addExposureWizard/components/groupDiffDisplay';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BondSelectBoxesComponent } from '../pages/modals/manualAccountManager/components/reusableFormComponents/bond-select-boxes.component';
import { AccountFeesEditorComponent } from '../pages/modals/manualAccountManager/components/reusableFormComponents/account-fees-editor.component';
import { CityStateZipFormComponent } from '../pages/modals/manualAccountManager/components/realAssetForm/components/cityStateZipForm.component';
import { DisclaimerComponent } from '../pages/modals/disclaimers/components/disclaimer.component';
import {
  NbAccordionModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbLayoutModule,
  NbListModule,
  NbPopoverModule,
  NbRadioModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbToggleModule,
  NbTooltipModule,
} from '@nebular/theme';
import { FastLinkUtil } from '../utils/fastLink.util';
import { NotificationsUtil } from '../utils/notifications.util';
import { DisclaimersUtil } from '../utils/disclaimers.util';
import { CdkStepper } from '@angular/cdk/stepper';
import { NgChartsModule } from 'ng2-charts';
import { OptimizerIconComponent } from '../reusableWidgets/optimizerIcon';
import { GlossaryComponent } from '../pages/modals/glossary/glossary.component';
import { GlossaryUtil } from '../utils/glossary.util';
import { DefinitionComponent } from '../pages/modals/glossary/components/definition.component';
import { OverrideDisplayComponent } from '../pages/modals/globalOverridesManager/overrideDisplay/override-display.component';
import { OverrideLegendComponent } from '../reusableWidgets/overrideLegend';
import { ConfirmationComponent } from '../pages/accounts/confirmation-dialog.component';
import { DeviationThresholdsComponent } from '../pages/modals/settings/components/deviationThresholds';
import { AppSettingsComponent } from '../pages/modals/settings/components/appSettings/app-settings.component';

import { AlertModalComponent } from '../reusableWidgets/alertModal';
import { AllocationWidgetComponent } from '../reusableWidgets/allocationWidget';
import { AutoCompleterComponent } from '../reusableWidgets/autoCompleter';
import {
  ArcSpinnerComponent,
  BarsSpinnerComponent,
  CircleSpinnerComponent,
  DotArcSpinnerComponent,
  DotCircleSpinnerComponent,
  DotsSpinnerComponent,
  HalfCircleSpinnerComponent,
  PauseCircleSpinnerComponent,
  ProgressBarButtonComponent,
  SpinnerButtonComponent,
} from '../reusableWidgets/spinners';
import { AccountSelectorComponent } from '../reusableWidgets/accountSelector';
import { CarouselComponent } from '../reusableWidgets/carousel';
import { InstitutionIconComponent } from '../reusableWidgets/institutionIcon';
import { CancelAccountRefreshButtonComponent } from '../reusableWidgets/cancelAccountRefreshButton';
import { SecurityQueryBoxComponent } from '../reusableWidgets/securityQueryBox';
import {
  TradingViewChartComponent,
  TradingViewCryptoMarketsComponent,
  TradingViewForexChartComponent,
  TradingViewFundamentalsComponent,
  TradingViewMarketDataComponent,
  TradingViewMarketOverviewComponent,
  TradingViewMiniChartComponent,
  TradingViewSingleTickerComponent,
  TradingViewSnapsComponent,
  TradingViewSymbolInfoComponent,
  TradingViewSymbolOverviewComponent,
  TradingViewTickerTapeComponent,
} from '../reusableWidgets/tradingView';
import { CryptoQueryBoxComponent } from '../reusableWidgets/cryptoQueryBox';
import { MarketIndexDataComponent } from '../reusableWidgets/marketData';
import { UsYieldCurveComponent } from '../reusableWidgets/usYieldCurve';
import { StockMarketsTabComponent } from '../reusableWidgets/stockMarkets';
import { StockdioChartComponent } from '../reusableWidgets/stockdio';
import { BondMarketsTabComponent } from '../reusableWidgets/bondMarkets';
import { VixChartComponent } from '../reusableWidgets/vixChart';
import { RiskReturnScatterPlotComponent } from '../reusableWidgets/riskReturnScatterPlot';
import { ZillowRatePickerComponent } from '../reusableWidgets/zillowRatePicker';
import { OneDayBreakdownComponent } from '../reusableWidgets/oneDayBreakdownDialog';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CorrelationMatrixComponent } from '../reusableWidgets/correlationMatrix/correlation-matrix.component';
import { MarketInfoSettingsComponent } from '../pages/modals/settings/components/marketInfoSettings/market-info-settings.component';
import { OneDayBreakdownDialogComponent } from '../pages/modals/oneDayBreakdownDialog/one-day-breakdown-dialog.component';
import { AppFooterComponent } from '../reusableWidgets/app-footer/app-footer.component';
import { MarketInfoUtil } from '../utils/market-info.util';
import {
  RssAppCarouselComponent,
  RssAppFeedComponent,
  RssAppListComponent,
  RssAppMagazineComponent,
  RssAppTickerComponent,
  RssAppWallComponent,
} from '../reusableWidgets/rssApp';
import { MarketsOverviewComponent } from '../reusableWidgets/marketsOverview/markets-overview.component';
import { IconUtil } from '../utils/icon.util';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { AddressAutocompleteComponent } from '../reusableWidgets/addressAutocomplete/address-autocomplete.component';
import { CovarianceMatrixComponent } from '../reusableWidgets/covarianceMatrix/covariance-matrix.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CurrencyInputComponent } from '../reusableWidgets/currencyInput';
import { TermRadioComponent } from '../reusableWidgets/termRadio/term-radio.component';
import { YieldTableComponent } from '../reusableWidgets/usYieldCurve/components/yield-table.component';
import { ForwardLookingHeaderComponent } from '../reusableWidgets/stockMarkets/components/forwardLookingHeader/forward-looking-header.component';
import { AddHouseholdMemberDialogComponent } from '../reusableWidgets/household-members/add-household-member-dialog.component';
import { EditHouseholdMemberDialogComponent } from '../reusableWidgets/household-members/edit-household-member-dialog.component';
import { DeleteHouseholdMemberDialogComponent } from '../reusableWidgets/household-members/delete-household-member-dialog.component';
import { HouseholdMemberFormComponent } from '../reusableWidgets/household-members/household-member-form.component';
import { HouseholdMembersComponent } from '../reusableWidgets/household-members/household-members.component';
import { HouseholdMembersState } from '../utils/household-members.state';
import { GoalsState } from '../utils/goals.state';
import { MonthOrYearCurrencyInputsComponent } from '../reusableWidgets/currencyInput/month-or-year-currency-inputs.component';
import { NgxColorsModule } from 'ngx-colors';
import { NgxStripeModule } from 'ngx-stripe';
import { LibTagModule } from '../shared/tag';
import { LibWorkspaceLoadDialogModule } from '../shared/workspace-load-dialog';
import { LibProgressSpinnerModule } from '../shared/progress-spinner';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { LibWorkspacePermissionModule } from '../shared/workspace-permission';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { DividerModule } from 'primeng/divider';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { SkeletonModule } from 'primeng/skeleton';
import { environment } from '../../environments/environment';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TreasuryRatesUtil } from '../utils/treasury-rates.util';
import { InsuranceCalculatorComponent } from '../reusableWidgets/insuranceCalculator';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import {
  HoldingDetailComponent,
  MobileAccountDetailsComponent,
  MobileAddManualAccountComponent,
  MobileDashboardChartsComponent,
  MobileDisclaimersComponent,
  MobileFastlinkComponent,
  MobileLogoLinkComponent,
  MobileMarketDataComponent,
  MobileMarketDataTickerComponent,
  MobileOneDayChangeComponent,
  MobileRowDetailComponent,
  NebularMobileLayoutComponent,
} from '../reusableWidgets/mobileLayout';
import { MarketInformationDashboardComponent } from '../reusableWidgets/marketInformationDashboard';
import { GoalsService } from '../globalData/goals.service';
import { RegistrationInfoCardsComponent } from '../reusableWidgets/regisrationInfoCards/registration-info-cards.component';
import { RegistrationFormComponent } from '../reusableWidgets/registrationForm/registration-form.component';
import { StripeService } from '../globalData/stripe.service';
import { RegistrationUtil } from '../utils/registration.util';
import { RegisterService } from '../pages/register/register.service';
import { HoldingsComponent } from '../reusableWidgets/holdings/holdings.component';
import { HoldingsToolbarComponent } from '../reusableWidgets/holdings/components/holdingsToolbar';
import { HoldingsFilterPresetMenuItemComponent } from '../reusableWidgets/holdings/components/holdingsToolbar/holdings-filter-preset-menu-item.component';
import { TransactionsComponent } from '../reusableWidgets/transactions';
import { IncomeStatementComponent } from '../reusableWidgets/transactions/components/income-statement/income-statement.component';
import { TransactionsTableComponent } from '../reusableWidgets/transactions/components/transactions-table/transactions-table.component';


const NGA_COMPONENTS = [
  AllocationWidgetComponent,
  BaBackTopComponent,
  BaContentTopComponent,
  BaMenuItemComponent,
  BaMenuComponent,
  BaSidebarComponent,
  ArcSpinnerComponent,
  BarsSpinnerComponent,
  CircleSpinnerComponent,
  DotArcSpinnerComponent,
  DotCircleSpinnerComponent,
  DotsSpinnerComponent,
  HalfCircleSpinnerComponent,
  PauseCircleSpinnerComponent,
  AlertModalComponent,
  AutoCompleterComponent,
  ColumnSelectorComponent,
  ColumnSelectorMenuComponent,
  ManualAccountManagerComponent,
  ManualAccountFormComponent,
  RealAssetFormComponent,
  StockFormComponent,
  StockOptionFormComponent,
  AnnuityFormComponent,
  BondFormComponent,
  BankFormComponent,
  CashFormComponent,
  CryptoFormComponent,
  InvestmentFormComponent,
  LoanFormComponent,
  TermLifeInsuranceFormComponent,
  InsuranceCalculatorComponent,
  PagesMenuComponent,
  SecurityFinderComponent,
  NewInvestmentSelectorComponent,
  NewInvestmentSelectorModalComponent,
  ScreenerComponent,
  MatchingFundDataSourceComponent,
  ProgressBarButtonComponent,
  SpinnerButtonComponent,
  PreferencesComponent,
  CarouselComponent,
  DisclaimersComponent,
  WidgetMenuComponent,
  UserMenuComponent,
  ManualSecurityEditorComponent,
  FastLinkComponent,
  ZillowRatePickerComponent,
  GoToGroupMenuComponent,
  BenchmarkGaugesComponent,
  MobileDashboardMenuComponent,
  AllocationChartsComponent,
  NotificationsComponent,
  NotificationComponent,
  StockdioChartComponent,
  AccountSelectorComponent,
  AddExposureWizardComponent,
  BenchmarkExposureChoicesComponent,
  ExposureChoicesComponent,
  ScreenerNavComponent,
  GroupDiffDisplayComponent,
  CancelAccountRefreshButtonComponent,
  AccountFeesEditorComponent,
  OneDayBreakdownComponent,
  InstitutionIconComponent,
  BondSelectBoxesComponent,
  CityStateZipFormComponent,
  SecurityQueryBoxComponent,
  DisclaimerComponent,
  OptimizerIconComponent,
  GlossaryComponent,
  DefinitionComponent,
  ConfirmationComponent,
  DeviationThresholdsComponent,
  AppSettingsComponent,
  MarketIndexDataComponent,
  UsYieldCurveComponent,
  OverrideDisplayComponent,
  OverrideLegendComponent,
  AddManualAccountWidgetComponent,
  OverrideDisplayComponent,
  OverrideLegendComponent,
  CryptoQueryBoxComponent,
  TradingViewChartComponent,
  TradingViewFundamentalsComponent,
  TradingViewMarketDataComponent,
  TradingViewMarketOverviewComponent,
  TradingViewMiniChartComponent,
  TradingViewSymbolOverviewComponent,
  TradingViewForexChartComponent,
  TradingViewCryptoMarketsComponent,
  TradingViewSnapsComponent,
  TradingViewSymbolInfoComponent,
  TradingViewSingleTickerComponent,
  TradingViewTickerTapeComponent,
  StockMarketsTabComponent,
  BondMarketsTabComponent,
  UsYieldCurveComponent,
  VixChartComponent,
  RiskReturnScatterPlotComponent,
  OneDayBreakdownDialogComponent,
  AppFooterComponent,
  RssAppWallComponent,
  RssAppListComponent,
  RssAppCarouselComponent,
  RssAppMagazineComponent,
  RssAppFeedComponent,
  CorrelationMatrixComponent,
  MarketInfoSettingsComponent,
  MarketsOverviewComponent,
  RssAppTickerComponent,
  CovarianceMatrixComponent,
  AddressAutocompleteComponent,
  MarketInfoSettingsComponent,
  MarketsOverviewComponent,
  CovarianceMatrixComponent,
  CurrencyInputComponent,
  YieldTableComponent,
  AddHouseholdMemberDialogComponent,
  EditHouseholdMemberDialogComponent,
  DeleteHouseholdMemberDialogComponent,
  HouseholdMembersComponent,
  TermRadioComponent,
  ForwardLookingHeaderComponent,
  HouseholdMemberFormComponent,
  MonthOrYearCurrencyInputsComponent,
  HouseholdMemberFormComponent,
  SidebarWorkspaceComponent,
  WorkspaceMenuComponent,
  WorkspaceMenuComponent,
  MobileMarketDataComponent,
  MobileOneDayChangeComponent,
  NebularMobileLayoutComponent,
  MobileDashboardChartsComponent,
  MobileDisclaimersComponent,
  MobileFastlinkComponent,
  MobileLogoLinkComponent,
  MobileAddManualAccountComponent,
  MobileRowDetailComponent,
  MobileDashboardMenuComponent,
  MobileAccountDetailsComponent,
  MobileMarketDataTickerComponent,
  HoldingDetailComponent,
  MarketInformationDashboardComponent,
  HoldingsComponent,
  HoldingsToolbarComponent,
  HoldingsFilterPresetMenuItemComponent,
  IncomeStatementComponent,
  TransactionsTableComponent,
  TransactionsComponent,
];

const NGA_DIRECTIVES = [
  BaScrollPositionDirective,
  BaSlimScrollDirective,
  BaThemeRunDirective,
  FocusDirective,
  RipFeatureToggleDirective,
  RipPrintDirective,
];

const NGA_PIPES = [
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
  SafePipe,
  StripHtmlPipe,
  RipsawCurrencyPipe,
  RipsawAbbreviatedNumberPipe,
  RipsawDecimalPipe,
  RipsawDatePipe,
  RipsawLongDatePipe,
  RipsawPercentPipe,
  RipsawTwoPercentPipe,
  RipsawLongPercentPipe,
  RipsawTruncatePipe,
  RipsawRemoveUnderscoresPipe,
  RipsawCreditDebitPipe,
  RipsawGoalOwnerNamesPipe,
  RipsawUnHyphenatePipe,
  RipsawCalendarYearPipePipe,
  RipDateInYearAndMonthPipe,
  ActivePricesPipe,
  AccountVisibilityBySettingPermissionPipe,
];

const NGA_SERVICES = [
  BaImageLoaderService,
  BaThemePreloader,
  RipThemeLoadingSpinnerService,
  BaMenuService,
  ProfileService,
  MarketDataService,
  DeviceDetectorService,
  NotificationsService,
  GoalsService,
  StripeService,
  RegistrationUtil,
  RegisterService,
  PromoCodeService,
  TransactionsService,
];

const NGA_VALIDATORS = [
  EmailValidator,
  EqualPasswordsValidator,
];

const RIPSAW_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NgxDatatableModule,
  MatInputModule,
  MatFormFieldModule,
  MatCheckboxModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatGridListModule,
  MatMenuModule,
  MatCardModule,
  MatExpansionModule,
  MatListModule,
  MatDialogModule,
  MatStepperModule,
  MatIconModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatSliderModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatTooltipModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatTabsModule,
  MatChipsModule,
  MatDatepickerModule,
  CdkTableModule,
  CdkTreeModule,
  FontAwesomeModule,
  MatRadioModule,
  MatSlideToggleModule,
  DragDropModule,
  NbButtonModule,
  NbStepperModule,
  NbTooltipModule,
  NbPopoverModule,
  NgChartsModule,
  NgxChartsModule,
  NbCardModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbRadioModule,
  NbBadgeModule,
  MatPasswordStrengthModule,
  NgxSliderModule,
  NbToggleModule,
  NgxColorsModule,
  NbAccordionModule,
  NgxStripeModule.forRoot( environment.stripePublishableKey ),
  LibTagModule,
  LibWorkspaceLoadDialogModule,
  LibProgressSpinnerModule,
  LibWorkspacePermissionModule,
  CdkMenuModule,
  CdkAccordionModule,
  NbListModule,
  SelectButtonModule,
  TooltipModule,
  DividerModule,
  InputSwitchModule,
  ToastModule,
  ButtonModule,
  SkeletonModule,
  OverlayPanelModule,
  TabViewModule,
  AccordionModule,
  TableModule,
  ChipModule,
];

const NGA_HELPERS = [
  OverrideHelpers,
  Util,
  MobileUtil,
  InvestmentSelectorUtil,
  FastLinkUtil,
  NotificationsUtil,
  DisclaimersUtil,
  GlossaryUtil,
  ManualAccountManagerState,
  MarketInfoUtil,
  IconUtil,
  HouseholdMembersState,
  GoalsState,
  TreasuryRatesUtil,
];

@NgModule( {
  declarations: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,
    RegistrationInfoCardsComponent,
    RegistrationFormComponent,
  ],
  imports: [
    ...RIPSAW_MODULES,
    NbLayoutModule,
    NbContextMenuModule,
  ],
  exports: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,
    RegistrationInfoCardsComponent,
    RegistrationFormComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
} )

export class NgaModule {
  static forRoot(): ModuleWithProviders<NgaModule> {
    return {
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_VALIDATORS,
        { provide: DateValidator, useValue: {} },
        ...NGA_HELPERS,
        ...NGA_PIPES,
        ...NGA_SERVICES,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
        { provide: CdkStepper, useValue: {} },
        { provide: ChangeDetectorRef, useValue: {} },
      ],
    };
  }
}
